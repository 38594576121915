import { ContainerType } from '@apis/type/container_type_pb'
import { DrugRiskClassification } from '@apis/type/ec_product_pb'
import { EcSite, LifeShopRegion } from '@apis/type/ec_site_pb'
import { Order } from '@apis/type/order_pb'
import { OrderReceiveKind } from '@apis/type/order_receive_type_pb'
import { Price } from '@apis/type/price_pb'
import { PriceType } from '@apis/type/price_type_pb'
import { SubscriptionPaymentDetail } from '@apis/type/subscription_payment_detail_pb'
import { SubscriptionPaymentMethodType } from '@apis/type/subscription_payment_method_pb'
import { RecurringOrderPaymentDetail } from '~/apis/type/recurring_order_payment_detail_pb'
import { RecurringOrderSetting } from '@apis/type/recurring_order_setting_pb'
import { CompensationEntry } from '~/apis/type/compensation_entry_pb'
import { TransactionProduct } from '@apis/type/transaction_product_pb'
import config, { Environment } from 'config'
import { DateTime } from 'luxon'
import Vue from 'vue'
import { PickingType } from '~/apis/stailer/externaladminrpc/pick_pack/pick_pack_option_pb'
import { User } from '~/types/user'
import { BundleType } from '~/apis/type/bundle_type_pb'
import { OrderReceiveKindType, OrderReceiveKindTypes } from '~/models/order_receive_kind'
import { getPartnerConfig } from '~/models/partner_config'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

declare module 'vue/types/vue' {
  interface Vue {
    $formatPrice(value: number, unit?: '¥' | '円'): string
    $formatNumber(value: number): string
    $formatContainerType(value: ContainerType | null): string
    $formatPriceType(value: PriceType | null | undefined): string
    $formatSuffixPriceType(value: PriceType | null): string
    $formatSuffixPbPrice(value: Price): string
    $formatDateTime(unixtime: number | undefined, format: string): string
    $formatterForDatePicker(date: Date): string
    $formatPostalCode(value: string): string
    $formatSiteType(siteType: EcSite.SiteType): string
    $getSiteTypeName(siteType: EcSite.SiteType): string
    $formatOrderStatus(value: Order.Status): string
    $formatReceiveType(value: OrderReceiveKind): string
    $formatOrderStatusDetailed(
      status: Order.Status,
      receiveKindType: OrderReceiveKindType,
      onHoldReason: Order.DeliveryOnHoldReason,
    ): string
    $formatTransactionProductStatus(value: TransactionProduct.Status): string
    $formatSalesBadge(siteType: EcSite.SiteType, value: number): string
    $formatDrugRiskClassification(value: DrugRiskClassification): string
    $formatBundleType(value: BundleType | undefined): string
    $formatPriceObject(value: Price.AsObject | undefined, unit?: '¥' | '円'): string
    $formatOrderPaymentMethod(value: Order.PaymentMethod | undefined): string
    $formatSubscriptionPaymentMethodType(value: SubscriptionPaymentMethodType | undefined): string
    $formatSubscriptionPaymentDetailObject(value: SubscriptionPaymentDetail.AsObject): string
    $formatRecurringOrderPaymentDetailObject(value: RecurringOrderPaymentDetail.AsObject): string
    $formatRecurringOrderSettingPaymentMethodType(
      value: RecurringOrderSetting.PaymentMethod.AsObject | undefined,
    ): string
    $formatMaskedCreditCardNumber(value: string): string
    $formatCreditCardExpiration(value: Timestamp.AsObject | undefined): string
    $formatMonthOffset(value: number): string
    $formatPickingType(value: PickingType | undefined): string
    $formatCompensationEntryPaymentMethod(
      value: CompensationEntry.PaymentMethod | undefined,
    ): string
    $isProduction(): boolean
    $formatPbPrice(value: Price, withSuffix: boolean): string
    $formatPbPriceWithBracketSuffix(value: Price): string
    $formatLifeShopRegion(value: LifeShopRegion): string
    $isFresta(signedInUser: User | null): boolean
  }
}

Vue.prototype.$formatPrice = (value: number, unit: '¥' | '円' = '¥') => {
  if (unit === '¥') {
    return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(value)
  } else {
    return new Intl.NumberFormat('ja-JP').format(value) + '円'
  }
}

Vue.prototype.$formatNumber = (value: number) => {
  return new Intl.NumberFormat('ja-JP').format(value)
}

Vue.prototype.$formatContainerType = (value: ContainerType | null) => {
  switch (value) {
    case ContainerType.NO_CONTAINER:
      return '大型'
    case ContainerType.CONTAINER_CHILLED:
      return '冷蔵'
    case ContainerType.CONTAINER_DRY:
      return '常温'
    case ContainerType.CONTAINER_FROZEN:
      return '冷凍'
    default:
      return '選択なし'
  }
}

Vue.prototype.$formatPriceType = (value: PriceType | null) => {
  switch (value) {
    case PriceType.PRICE_TYPE_10_PERCENT_TAX_EXCLUDED:
      return '外税(10%)'
    case PriceType.PRICE_TYPE_08_PERCENT_TAX_EXCLUDED:
      return '外税(8%)'
    case PriceType.PRICE_TYPE_10_PERCENT_TAX_INCLUDED:
      return '内税(10%)'
    case PriceType.PRICE_TYPE_08_PERCENT_TAX_INCLUDED:
      return '内税(8%)'
    case PriceType.PRICE_TYPE_TAX_EXEMPT:
      return '非課税'
    default:
      return '選択なし'
  }
}

Vue.prototype.$formatSuffixPriceType = (value: PriceType | null) => {
  const priceType = (() => {
    switch (value) {
      case PriceType.PRICE_TYPE_UNSPECIFIED:
        return '未指定'
      case PriceType.PRICE_TYPE_10_PERCENT_TAX_EXCLUDED:
        return '外税10%'
      case PriceType.PRICE_TYPE_08_PERCENT_TAX_EXCLUDED:
        return '外税8%'
      case PriceType.PRICE_TYPE_10_PERCENT_TAX_INCLUDED:
        return '内税10%'
      case PriceType.PRICE_TYPE_08_PERCENT_TAX_INCLUDED:
        return '内税8%'
      case PriceType.PRICE_TYPE_TAX_EXEMPT:
        return '非課税'
    }
  })()
  return `[${priceType}]`
}

Vue.prototype.$formatPbPrice = (value: Price, withSuffix: boolean = true) => {
  switch (value.getPriceCase()) {
    case Price.PriceCase.TAX_EXCLUDED_PRICE:
      return `${Vue.prototype.$formatPrice(value.getTaxExcludedPrice()?.getAmount())}${
        withSuffix ? ` 外税(${Math.round(value.getTaxExcludedPrice()?.getTaxRate()! * 100)}%)` : ''
      }`
    case Price.PriceCase.TAX_INCLUDED_PRICE:
      return `${Vue.prototype.$formatPrice(value.getTaxIncludedPrice()?.getAmount())}${
        withSuffix ? ` 内税(${Math.round(value?.getTaxIncludedPrice()?.getTaxRate()! * 100)}%)` : ''
      }`
    case Price.PriceCase.TAX_EXEMPT_PRICE:
      return `${Vue.prototype.$formatPrice(value.getTaxExemptPrice()?.getAmount())}${
        withSuffix ? ' 非課税' : ''
      }}`
    case Price.PriceCase.PRICE_NOT_SET:
    default:
      throw new Error('想定していないPriceCaseです')
  }
}

Vue.prototype.$formatPbPriceWithBracketSuffix = (value: Price) => {
  switch (value.getPriceCase()) {
    case Price.PriceCase.TAX_EXCLUDED_PRICE:
      return `${Vue.prototype.$formatPrice(value.getTaxExcludedPrice()?.getAmount())} [外税${Math.round(value.getTaxExcludedPrice()?.getTaxRate()! * 100)}%]`
    case Price.PriceCase.TAX_INCLUDED_PRICE:
      return `${Vue.prototype.$formatPrice(value.getTaxIncludedPrice()?.getAmount())} [内税(${Math.round(value?.getTaxIncludedPrice()?.getTaxRate()! * 100)}%]`
    case Price.PriceCase.TAX_EXEMPT_PRICE:
      return `${Vue.prototype.$formatPrice(value.getTaxExemptPrice()?.getAmount())} [非課税]`
    case Price.PriceCase.PRICE_NOT_SET:
    default:
      throw new Error('想定していないPriceCaseです')
  }
}

Vue.prototype.$formatSuffixPbPrice = (value: Price) => {
  switch (value.getPriceCase()) {
    case Price.PriceCase.TAX_EXCLUDED_PRICE:
      return `外税${Math.round(value.getTaxExcludedPrice()?.getTaxRate()! * 100)}%`
    case Price.PriceCase.TAX_INCLUDED_PRICE:
      return `内税${Math.round(value?.getTaxIncludedPrice()?.getTaxRate()! * 100)}%`
    case Price.PriceCase.TAX_EXEMPT_PRICE:
      return `非課税`
    case Price.PriceCase.PRICE_NOT_SET:
    default:
      throw new Error('想定していないPriceCaseです')
  }
}

Vue.prototype.$formatDateTime = (unixtime: number | undefined, format: string): string => {
  if (unixtime == null) {
    return ''
  }
  const datetime = DateTime.fromSeconds(unixtime)
  return datetime.toFormat(format)
}

Vue.prototype.$formatterForDatePicker = (date: Date): string => {
  return new Intl.DateTimeFormat('ja-JP', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'Asia/Tokyo',
  }).format(date)
}

Vue.prototype.$formatPostalCode = (value: string): string => {
  return `${value.slice(0, 3)}-${value.slice(3, 8)}`
}

Vue.prototype.$formatSiteType = (value: EcSite.SiteType): string => {
  // https://www.notion.so/10xall/Partner-Setting-List-8a48e2120956422a8f148af9b5242746 のアプリ名を参照
  switch (value) {
    case EcSite.SiteType.ITOYOKADO:
      return 'イトーヨーカドー'
    case EcSite.SiteType.LIFE:
      return 'ライフネットスーパー'
    case EcSite.SiteType.ALBIS:
      return 'アルビスネットスーパー'
    case EcSite.SiteType.FRESTA:
      return 'エブリデイフレスタ'
    case EcSite.SiteType.YAKUODO:
      return '薬王堂 P!ck and'
    case EcSite.SiteType.CREATESD:
      return 'REMO CARTリモカート'
    case EcSite.SiteType.SUGI:
      return 'スギスマホオーダー'
    case EcSite.SiteType.DELICIA:
      return 'デリシアネットスーパー'
    case EcSite.SiteType.HEIWADO:
      return '平和堂ネットスーパー'
    case EcSite.SiteType.LADYDRUG:
      return 'レデイのぽちっとオーダー'
    case EcSite.SiteType.OGINO:
      return 'オギノネットスーパー'
    case EcSite.SiteType.SEISENICHIBATOP:
      return '生鮮市場TOP！ネットスーパー'
    case EcSite.SiteType.ARUK:
      return 'アルクネットスーパー'
    case EcSite.SiteType.FUJI:
      return 'フジネットスーパーおまかせくん'
    case EcSite.SiteType.TORISEN:
      return 'とりせんネットスーパー'
    case EcSite.SiteType.DEMO:
      return 'Stailer Demo'
    default:
      throw new Error(
        `unexpected siteType: ${value}. $formatSiteType does not support the siteType name.`,
      )
  }
}

Vue.prototype.$getSiteTypeName = (value: EcSite.SiteType): string => {
  switch (value) {
    case EcSite.SiteType.NO_TYPE:
      return 'NO_TYPE'
    case EcSite.SiteType.ITOYOKADO:
      return 'ITOYOKADO'
    case EcSite.SiteType.SEIYU:
      return 'SEIYU'
    case EcSite.SiteType.COOPDELI:
      return 'COOPDELI'
    case EcSite.SiteType.PALSYSTEM:
      return 'PALSYSTEM'
    case EcSite.SiteType.SEIKATSUCLUB:
      return 'SEIKATSUCLUB'
    case EcSite.SiteType.AEON:
      return 'AEON'
    case EcSite.SiteType.LIFE:
      return 'LIFE'
    case EcSite.SiteType.ALBIS:
      return 'ALBIS'
    case EcSite.SiteType.FRESTA:
      return 'FRESTA'
    case EcSite.SiteType.YAKUODO:
      return 'YAKUODO'
    case EcSite.SiteType.DEMO:
      return 'DEMO'
    case EcSite.SiteType.DEPRECATED_LIFE2:
      return 'DEPRECATED_LIFE2'
    case EcSite.SiteType.CHATERAISE:
      return 'CHATERAISE'
    case EcSite.SiteType.CREATESD:
      return 'CREATESD'
    case EcSite.SiteType.HEIWADO:
      return 'HEIWADO'
    case EcSite.SiteType.SUGI:
      return 'SUGI'
    case EcSite.SiteType.KIRINDO:
      return 'KIRINDO'
    case EcSite.SiteType.COSTCO:
      return 'COSTCO'
    case EcSite.SiteType.DELICIA:
      return 'DELICIA'
    case EcSite.SiteType.LADYDRUG:
      return 'LADYDRUG'
    case EcSite.SiteType.MARUKYU:
      return 'MARUKYU'
    case EcSite.SiteType.OGINO:
      return 'OGINO'
    case EcSite.SiteType.MAMIMART:
      return 'MAMIMART'
    case EcSite.SiteType.TORISEN:
      return 'TORISEN'
    case EcSite.SiteType.SEISENICHIBATOP:
      return 'SEISENICHIBATOP'
    case EcSite.SiteType.ARUK:
      return 'ARUK'
    case EcSite.SiteType.FUJI:
      return 'FUJI'
    default:
      throw new Error(
        `unexpected siteType: ${value}. $getSiteTypeName does not support the siteType.`,
      )
  }
}

Vue.prototype.$formatOrderStatus = (value: Order.Status) => {
  switch (value) {
    case Order.Status.NONE:
      return 'なし'
    case Order.Status.PREPARING:
      return '商品の準備中'
    case Order.Status.PREPARED:
      return '準備完了（受け取り可能）'
    case Order.Status.DELIVERING:
      return '配達中'
    case Order.Status.RECEIVED:
      return '配達・受け取り済み'
    case Order.Status.CANCELED:
      return 'キャンセル'
    case Order.Status.ARRIVED:
      return '到着'
    case Order.Status.DELIVERY_ON_HOLD:
      return '配達保留中'
    case Order.Status.LOCKER_STORED:
      return 'ロッカー格納済'
    default:
      return '不正なステータス'
  }
}

Vue.prototype.$formatReceiveType = (value: OrderReceiveKind) => {
  switch (value) {
    case OrderReceiveKind.DELIVERY:
      return '配達'
    case OrderReceiveKind.PICKUP_SHOP:
      return '店舗受け取り'
    case OrderReceiveKind.PICKUP_CAR:
      return '車上受け取り'
    case OrderReceiveKind.NONE_RECEIVE_TYPE:
      return 'なし'
    case OrderReceiveKind.PICKUP_LOCKER:
      return 'ロッカー受け取り'
    case OrderReceiveKind.PICKUP_OTHER_SHOP:
      return '担当店舗以外での受け取り'
    case OrderReceiveKind.DELIVERY_UNATTENDED:
      return '置き配'
    case OrderReceiveKind.DELIVERY_CONTACTLESS:
      return '非接触配達'
    default:
      throw new Error(`unexpected value: ${value}`)
  }
}

Vue.prototype.$formatOrderStatusDetailed = (
  status: Order.Status,
  receiveKindType: OrderReceiveKindType,
  onHoldReason: Order.DeliveryOnHoldReason,
) => {
  let reason = ''
  switch (status) {
    case Order.Status.NONE:
      return 'なし'
    case Order.Status.PREPARING:
      return '商品の準備中'
    case Order.Status.PREPARED:
      return '準備完了（受け取り可能）'
    case Order.Status.DELIVERING:
      if (receiveKindType === OrderReceiveKindTypes.Delivery) {
        return '配達中'
      } else if (receiveKindType === OrderReceiveKindTypes.Pickup) {
        return 'お渡し中'
      } else {
        return '配達中'
      }
    case Order.Status.RECEIVED:
      return '配達・受け取り済み'
    case Order.Status.CANCELED:
      return 'キャンセル'
    case Order.Status.ARRIVED:
      return '到着'
    case Order.Status.DELIVERY_ON_HOLD:
      reason = ((onHoldReason: Order.DeliveryOnHoldReason) => {
        switch (onHoldReason) {
          case Order.DeliveryOnHoldReason.PENDING:
            return '保留'
          case Order.DeliveryOnHoldReason.ABSENCE:
            return '不在'
          default:
            return ''
        }
      })(onHoldReason)
      return `配達保留中${reason.length > 0 ? `（${reason}）` : ''}`
    case Order.Status.LOCKER_STORED:
      return 'ロッカー格納済'
    default:
      return '不正なステータス'
  }
}

Vue.prototype.$formatTransactionProductStatus = (value: TransactionProduct.Status) => {
  switch (value) {
    case TransactionProduct.Status.WAITING_FOR_PICKING:
      return 'ピッキング待ち'
    case TransactionProduct.Status.PICKED:
      return 'ピッキング完了'
    case TransactionProduct.Status.ON_HOLD:
      return '保留中(売り場に商品がない)'
    case TransactionProduct.Status.PACKED:
      return 'パッキング済み'
    case TransactionProduct.Status.WAITING_FOR_ALTERNATIVE:
      return '代替品待ち'
    case TransactionProduct.Status.STOCK_OUT:
      return '品切れ(キャンセル)'
    case TransactionProduct.Status.UNABLE_TO_STORE_TO_RECEIVING_LOCKER:
      return '受取ロッカー格納不可(キャンセル)'
    case TransactionProduct.Status.DEFECTIVE_PRODUCT:
      return '商品不良(キャンセル)'
    case TransactionProduct.Status.CANCEL_BY_CUSTOMER:
      return 'お客様要望のキャンセル(ピッキング後)'
    default:
      throw new Error(`unexpected value: ${value}`)
  }
}

Vue.prototype.$formatSalesBadge = (siteType: EcSite.SiteType, value: number) => {
  const badges = getPartnerConfig(siteType).salesBadges
  const badge = badges.find((e) => e.value === value)
  if (badge === undefined) {
    throw new Error(`unexpected value: ${value}`)
  }
  return badge.label
}

Vue.prototype.$formatDrugRiskClassification = (value: DrugRiskClassification) => {
  switch (value) {
    case DrugRiskClassification.NONE:
      return '未指定'
    case DrugRiskClassification.FIRST:
      return '第1類'
    case DrugRiskClassification.SPECIFIED_SECOND:
      return '指定第2類'
    case DrugRiskClassification.SECOND:
      return '第2類'
    case DrugRiskClassification.THIRD:
      return '第3類'
    case DrugRiskClassification.QUASI:
      return '医薬部外品'
    case DrugRiskClassification.REQUIRING_GUIDANCE:
      return '要指導'
    case DrugRiskClassification.ETHICAL_DRUG:
      return '医療用医薬品'
    case DrugRiskClassification.POISONOUS_AND_DELETERIOUS:
      return '毒劇物取扱'
    case DrugRiskClassification.MEDICAL_DEVICE:
      return '管理医療機器'
    case DrugRiskClassification.SPECIAL_MEDICAL_DEVICE:
      return '高度管理医療機器'
    default:
      return '不正な値'
  }
}

Vue.prototype.$formatBundleType = (value: number) => {
  switch (value) {
    case BundleType.BUNDLE_TYPE_BUNDLE:
      return 'まとめ割'
    case BundleType.BUNDLE_TYPE_MIX_MATCH:
      return 'よりどり割'
    default:
      return ''
  }
}

Vue.prototype.$formatPriceObject = (value: Price.AsObject, unit: '¥' | '円' = '¥') => {
  if (value.taxExcludedPrice) {
    return Vue.prototype.$formatPrice(value.taxExcludedPrice.amount, unit) + '（税抜）'
  } else if (value.taxIncludedPrice) {
    return Vue.prototype.$formatPrice(value.taxIncludedPrice.amount, unit) + '（税込）'
  } else if (value.taxExemptPrice) {
    return Vue.prototype.$formatPrice(value.taxExemptPrice.amount, unit) + '（非課税）'
  } else {
    throw new Error(`想定外のPrice: ${value}`)
  }
}

Vue.prototype.$formatOrderPaymentMethod = (value: Order.PaymentMethod | undefined) => {
  switch (value) {
    case Order.PaymentMethod.CREDIT_CARD:
      return 'クレジットカード'
    case Order.PaymentMethod.LOCAL_PAYMENT:
      return 'レジ支払い'
    case Order.PaymentMethod.NONE_PAYMENT_METHOD:
      return ''
    case Order.PaymentMethod.CASH_ON_DELIVERY:
      return '代金引換'
    case Order.PaymentMethod.INVOICE:
      return '掛売・請求書'
    case Order.PaymentMethod.BANK_ACCOUNT_TRANSFER:
      return '口座振替'
    case Order.PaymentMethod.COUPON:
      return 'クーポン'
    case Order.PaymentMethod.POINT:
      return 'ポイント'
    default:
      throw new Error(`想定外のPaymentMethod: ${value}`)
  }
}

Vue.prototype.$formatSubscriptionPaymentMethodType = (
  value: SubscriptionPaymentMethodType | undefined,
) => {
  switch (value) {
    case SubscriptionPaymentMethodType.CREDIT_CARD:
      return 'クレジットカード決済'
    case SubscriptionPaymentMethodType.BANK_ACCOUNT_TRANSFER:
      return '口座振替'
    default:
      throw new Error(`想定外のSubscriptionPaymentMethod: ${value}`)
  }
}

Vue.prototype.$formatSubscriptionPaymentDetailObject = (
  value: SubscriptionPaymentDetail.AsObject,
) => {
  switch (value.paymentMethod?.type) {
    case SubscriptionPaymentMethodType.CREDIT_CARD:
      return `クレジットカード (${Vue.prototype.$formatMaskedCreditCardNumber(
        value.cryptedCreditCard?.maskedCardNumber,
      )}、有効期限 ${Vue.prototype.$formatCreditCardExpiration(
        value.cryptedCreditCard?.expiresAt,
      )})`
    case SubscriptionPaymentMethodType.BANK_ACCOUNT_TRANSFER:
      return '口座振替'
    default:
      throw new Error(`想定外のSubscriptionPaymentMethod: ${value.paymentMethod?.type}`)
  }
}

Vue.prototype.$formatRecurringOrderPaymentDetailObject = (
  value: RecurringOrderPaymentDetail.AsObject,
) => {
  switch (value.paymentMethod?.type) {
    case RecurringOrderSetting.PaymentMethodType.CREDIT_CARD:
      return `クレジットカード (${Vue.prototype.$formatMaskedCreditCardNumber(
        value.cryptedCreditCard?.maskedCardNumber,
      )}、有効期限 ${Vue.prototype.$formatCreditCardExpiration(
        value.cryptedCreditCard?.expiresAt,
      )})`
    case RecurringOrderSetting.PaymentMethodType.BANK_ACCOUNT_TRANSFER:
      return '口座振替'
    default:
      throw new Error(`想定外のRecurringOrderPaymentMethod: ${value.paymentMethod?.type}`)
  }
}

Vue.prototype.$formatRecurringOrderSettingPaymentMethodType = (
  value: RecurringOrderSetting.PaymentMethod.AsObject,
) => {
  if (value?.bankAccountId !== '') return '口座振替'
  if (value?.creditCardId !== '') return 'クレジットカード'
  throw new Error(`想定外のRecurringOrderSetting.PaymentMethod: ${value}`)
}

Vue.prototype.$formatMaskedCreditCardNumber = (value: string | undefined) => {
  return value ? value.match(/.{1,4}/g)?.join('-') : ''
}

Vue.prototype.$formatCreditCardExpiration = (value: Timestamp.AsObject | undefined) => {
  return value ? DateTime.fromSeconds(value.seconds).toFormat('MM/yy') : ''
}

Vue.prototype.$formatMonthOffset = (value: number) => {
  switch (value) {
    case -2:
      return '前々月'
    case -1:
      return '前月'
    case 0:
      return '当月'
    case 1:
      return '翌月'
    case 2:
      return '翌々月'
    default:
      throw new Error(`想定外のoffset: ${value}`)
  }
}
Vue.prototype.$formatPickingType = (pickingType?: PickingType) => {
  switch (pickingType) {
    case PickingType.TOTAL:
      return '総量ピック'
    case PickingType.SINGLE_BASKET:
      return 'シングルバスケットピック'
    default:
      return ''
  }
}

Vue.prototype.$formatCompensationEntryPaymentMethod = (
  value: CompensationEntry.PaymentMethod | undefined,
) => {
  switch (value) {
    case CompensationEntry.PaymentMethod.CARD:
      return 'クレジットカード'
    case CompensationEntry.PaymentMethod.BANK_ACCOUNT_TRANSFER:
      return '口座振替'
    default:
      throw new Error(`想定外のCompensationEntry.PaymentMethod: ${value}`)
  }
}

Vue.prototype.$formatLifeShopRegion = (value: LifeShopRegion) => {
  switch (value) {
    case LifeShopRegion.LIFE_SHOP_REGION_UNSPECIFIED:
      return '(未設定)'
    case LifeShopRegion.LIFE_SHOP_REGION_KINKI:
      return '近畿圏'
    case LifeShopRegion.LIFE_SHOP_REGION_SHUTOKEN:
      return '首都圏'
  }
}

Vue.prototype.$isProduction = () => {
  return config.environment === Environment.production
}

Vue.prototype.$isFresta = (signedInUser: User | null) => {
  return signedInUser?.siteType === EcSite.SiteType.FRESTA
}
