// source: type/ec_site.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var type_delivery_type_pb = require('../type/delivery_type_pb.js');
goog.object.extend(proto, type_delivery_type_pb);
var type_delivery_time_pb = require('../type/delivery_time_pb.js');
goog.object.extend(proto, type_delivery_time_pb);
var type_delivery_week_pb = require('../type/delivery_week_pb.js');
goog.object.extend(proto, type_delivery_week_pb);
var type_order_receive_type_pb = require('../type/order_receive_type_pb.js');
goog.object.extend(proto, type_order_receive_type_pb);
var type_address_pb = require('../type/address_pb.js');
goog.object.extend(proto, type_address_pb);
var type_receive_method_pb = require('../type/receive_method_pb.js');
goog.object.extend(proto, type_receive_method_pb);
var type_delivery_option_pb = require('../type/delivery_option_pb.js');
goog.object.extend(proto, type_delivery_option_pb);
var type_pickup_option_pb = require('../type/pickup_option_pb.js');
goog.object.extend(proto, type_pickup_option_pb);
goog.exportSymbol('proto.type.EcShopPickupType', null, global);
goog.exportSymbol('proto.type.EcShopPickupType.Type', null, global);
goog.exportSymbol('proto.type.EcShopType', null, global);
goog.exportSymbol('proto.type.EcShopType.Type', null, global);
goog.exportSymbol('proto.type.EcSite', null, global);
goog.exportSymbol('proto.type.EcSite.SiteType', null, global);
goog.exportSymbol('proto.type.EcSitePickupShop', null, global);
goog.exportSymbol('proto.type.EcSiteShop', null, global);
goog.exportSymbol('proto.type.EcSiteShopDetail', null, global);
goog.exportSymbol('proto.type.HealthDeptStatus', null, global);
goog.exportSymbol('proto.type.HealthDeptStatus.Type', null, global);
goog.exportSymbol('proto.type.LifeShopRegion', null, global);
goog.exportSymbol('proto.type.PickupCarInformation', null, global);
goog.exportSymbol('proto.type.PickupLockerInformation', null, global);
goog.exportSymbol('proto.type.PickupShopInformation', null, global);
goog.exportSymbol('proto.type.UnavailableEcSite', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.EcSite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.EcSite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.EcSite.displayName = 'proto.type.EcSite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.EcShopType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.EcShopType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.EcShopType.displayName = 'proto.type.EcShopType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.HealthDeptStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.HealthDeptStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.HealthDeptStatus.displayName = 'proto.type.HealthDeptStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.EcSiteShop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.type.EcSiteShop.repeatedFields_, null);
};
goog.inherits(proto.type.EcSiteShop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.EcSiteShop.displayName = 'proto.type.EcSiteShop';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.EcShopPickupType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.EcShopPickupType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.EcShopPickupType.displayName = 'proto.type.EcShopPickupType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.EcSitePickupShop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.type.EcSitePickupShop.repeatedFields_, null);
};
goog.inherits(proto.type.EcSitePickupShop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.EcSitePickupShop.displayName = 'proto.type.EcSitePickupShop';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.UnavailableEcSite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.UnavailableEcSite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.UnavailableEcSite.displayName = 'proto.type.UnavailableEcSite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.EcSiteShopDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.type.EcSiteShopDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.EcSiteShopDetail.displayName = 'proto.type.EcSiteShopDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.PickupShopInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.type.PickupShopInformation.repeatedFields_, null);
};
goog.inherits(proto.type.PickupShopInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.PickupShopInformation.displayName = 'proto.type.PickupShopInformation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.PickupCarInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.type.PickupCarInformation.repeatedFields_, null);
};
goog.inherits(proto.type.PickupCarInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.PickupCarInformation.displayName = 'proto.type.PickupCarInformation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.type.PickupLockerInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.type.PickupLockerInformation.repeatedFields_, null);
};
goog.inherits(proto.type.PickupLockerInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.type.PickupLockerInformation.displayName = 'proto.type.PickupLockerInformation';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.EcSite.prototype.toObject = function(opt_includeInstance) {
  return proto.type.EcSite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.EcSite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.EcSite.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    colorCode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of EcSite as accepted by the `fromObject` method.
 * @record
 */
proto.type.EcSite.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.siteType;

  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.colorCode;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.EcSite.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.EcSite}
 */
proto.type.EcSite.fromObject = function(obj) {
  var msg = new proto.type.EcSite();
  obj.siteType != null && jspb.Message.setField(msg, 1, obj.siteType);
  obj.name != null && jspb.Message.setField(msg, 2, obj.name);
  obj.colorCode != null && jspb.Message.setField(msg, 3, obj.colorCode);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.EcSite}
 */
proto.type.EcSite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.EcSite;
  return proto.type.EcSite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.EcSite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.EcSite}
 */
proto.type.EcSite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.type.EcSite.SiteType} */ (reader.readEnum());
      msg.setSiteType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setColorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.EcSite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.EcSite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.EcSite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.EcSite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getColorCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.type.EcSite.SiteType = {
  NO_TYPE: 0,
  ITOYOKADO: 1,
  SEIYU: 2,
  COOPDELI: 3,
  PALSYSTEM: 4,
  SEIKATSUCLUB: 5,
  AEON: 6,
  LIFE: 7,
  ALBIS: 8,
  FRESTA: 9,
  YAKUODO: 10,
  DEMO: 11,
  DEPRECATED_LIFE2: 12,
  CHATERAISE: 13,
  CREATESD: 14,
  HEIWADO: 15,
  SUGI: 16,
  KIRINDO: 17,
  COSTCO: 18,
  DELICIA: 19,
  LADYDRUG: 20,
  MARUKYU: 21,
  OGINO: 22,
  MAMIMART: 23,
  TORISEN: 24,
  SEISENICHIBATOP: 25,
  ARUK: 26,
  FUJI: 27
};

/**
 * optional SiteType site_type = 1;
 * @return {!proto.type.EcSite.SiteType}
 */
proto.type.EcSite.prototype.getSiteType = function() {
  return /** @type {!proto.type.EcSite.SiteType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.type.EcSite.SiteType} value
 * @return {!proto.type.EcSite} returns this
 */
proto.type.EcSite.prototype.setSiteType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.type.EcSite.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSite} returns this
 */
proto.type.EcSite.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string color_code = 3;
 * @return {string}
 */
proto.type.EcSite.prototype.getColorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSite} returns this
 */
proto.type.EcSite.prototype.setColorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.EcShopType.prototype.toObject = function(opt_includeInstance) {
  return proto.type.EcShopType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.EcShopType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.EcShopType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of EcShopType as accepted by the `fromObject` method.
 * @record
 */
proto.type.EcShopType.ObjectFormat = function() {
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.EcShopType.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.EcShopType}
 */
proto.type.EcShopType.fromObject = function(obj) {
  var msg = new proto.type.EcShopType();
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.EcShopType}
 */
proto.type.EcShopType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.EcShopType;
  return proto.type.EcShopType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.EcShopType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.EcShopType}
 */
proto.type.EcShopType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.EcShopType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.EcShopType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.EcShopType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.EcShopType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.type.EcShopType.Type = {
  NO_TYPE: 0,
  AEON_V1: 1,
  AEON_V2: 2,
  AEON_HOKKAIDO: 3,
  NEW_LIFE: 4,
  NEW_FRESTA: 5
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.HealthDeptStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.type.HealthDeptStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.HealthDeptStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.HealthDeptStatus.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of HealthDeptStatus as accepted by the `fromObject` method.
 * @record
 */
proto.type.HealthDeptStatus.ObjectFormat = function() {
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.HealthDeptStatus.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.HealthDeptStatus}
 */
proto.type.HealthDeptStatus.fromObject = function(obj) {
  var msg = new proto.type.HealthDeptStatus();
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.HealthDeptStatus}
 */
proto.type.HealthDeptStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.HealthDeptStatus;
  return proto.type.HealthDeptStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.HealthDeptStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.HealthDeptStatus}
 */
proto.type.HealthDeptStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.HealthDeptStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.HealthDeptStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.HealthDeptStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.HealthDeptStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.type.HealthDeptStatus.Type = {
  STATUS_UNSPECIFIED: 0,
  STATUS_PENDING: 1,
  STATUS_APPROVED: 2
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.type.EcSiteShop.repeatedFields_ = [6,15,17,20,21,22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.EcSiteShop.prototype.toObject = function(opt_includeInstance) {
  return proto.type.EcSiteShop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.EcSiteShop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.EcSiteShop.toObject = function(includeInstance, msg) {
  var f, obj = {
    ecSite: (f = msg.getEcSite()) && proto.type.EcSite.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    deliveryType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    deliveryTimesList: jspb.Message.toObjectList(msg.getDeliveryTimesList(),
    type_delivery_time_pb.DeliveryTime.toObject, includeInstance),
    deliveryWeek: (f = msg.getDeliveryWeek()) && type_delivery_week_pb.DeliveryWeek.toObject(includeInstance, f),
    isDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    url: jspb.Message.getFieldWithDefault(msg, 9, ""),
    useExternalTransportManagementSystem: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    externalTransportManagementSystemUrl: jspb.Message.getFieldWithDefault(msg, 12, ""),
    isPractice: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    address: (f = msg.getAddress()) && type_address_pb.Address.toObject(includeInstance, f),
    allowedUserIdsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    releasedAt: (f = msg.getReleasedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    supportedOrderReceiveKindsList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
    shopId: jspb.Message.getFieldWithDefault(msg, 18, ""),
    healthDeptStatus: jspb.Message.getFieldWithDefault(msg, 19, 0),
    availableReceiveMethodsList: jspb.Message.toObjectList(msg.getAvailableReceiveMethodsList(),
    type_receive_method_pb.ReceiveMethod.toObject, includeInstance),
    availableDeliveryOptionsList: jspb.Message.toObjectList(msg.getAvailableDeliveryOptionsList(),
    type_delivery_option_pb.DeliveryOption.toObject, includeInstance),
    availablePickupOptionsList: jspb.Message.toObjectList(msg.getAvailablePickupOptionsList(),
    type_pickup_option_pb.PickupOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of EcSiteShop as accepted by the `fromObject` method.
 * @record
 */
proto.type.EcSiteShop.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.ecSite;

  /** @type {?|undefined} */
  this.id;

  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.type;

  /** @type {?|undefined} */
  this.deliveryType;

  /** @type {?|undefined} */
  this.deliveryTimesList;

  /** @type {?|undefined} */
  this.deliveryWeek;

  /** @type {?|undefined} */
  this.isDeleted;

  /** @type {?|undefined} */
  this.url;

  /** @type {?|undefined} */
  this.useExternalTransportManagementSystem;

  /** @type {?|undefined} */
  this.externalTransportManagementSystemUrl;

  /** @type {?|undefined} */
  this.isPractice;

  /** @type {?|undefined} */
  this.address;

  /** @type {?|undefined} */
  this.allowedUserIdsList;

  /** @type {?|undefined} */
  this.releasedAt;

  /** @type {?|undefined} */
  this.supportedOrderReceiveKindsList;

  /** @type {?|undefined} */
  this.shopId;

  /** @type {?|undefined} */
  this.healthDeptStatus;

  /** @type {?|undefined} */
  this.availableReceiveMethodsList;

  /** @type {?|undefined} */
  this.availableDeliveryOptionsList;

  /** @type {?|undefined} */
  this.availablePickupOptionsList;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.EcSiteShop.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.EcSiteShop}
 */
proto.type.EcSiteShop.fromObject = function(obj) {
  var msg = new proto.type.EcSiteShop();
  obj.ecSite && jspb.Message.setWrapperField(
      msg, 1, proto.type.EcSite.fromObject(obj.ecSite));
  obj.id != null && jspb.Message.setField(msg, 2, obj.id);
  obj.name != null && jspb.Message.setField(msg, 3, obj.name);
  obj.type != null && jspb.Message.setField(msg, 4, obj.type);
  obj.deliveryType != null && jspb.Message.setField(msg, 5, obj.deliveryType);
  obj.deliveryTimesList && jspb.Message.setRepeatedWrapperField(
      msg, 6, obj.deliveryTimesList.map(
          type_delivery_time_pb.DeliveryTime.fromObject));
  obj.deliveryWeek && jspb.Message.setWrapperField(
      msg, 7, type_delivery_week_pb.DeliveryWeek.fromObject(obj.deliveryWeek));
  obj.isDeleted != null && jspb.Message.setField(msg, 8, obj.isDeleted);
  obj.url != null && jspb.Message.setField(msg, 9, obj.url);
  obj.useExternalTransportManagementSystem != null && jspb.Message.setField(msg, 11, obj.useExternalTransportManagementSystem);
  obj.externalTransportManagementSystemUrl != null && jspb.Message.setField(msg, 12, obj.externalTransportManagementSystemUrl);
  obj.isPractice != null && jspb.Message.setField(msg, 13, obj.isPractice);
  obj.address && jspb.Message.setWrapperField(
      msg, 14, type_address_pb.Address.fromObject(obj.address));
  obj.allowedUserIdsList != null && jspb.Message.setField(msg, 15, obj.allowedUserIdsList);
  obj.releasedAt && jspb.Message.setWrapperField(
      msg, 16, google_protobuf_timestamp_pb.Timestamp.fromObject(obj.releasedAt));
  obj.supportedOrderReceiveKindsList != null && jspb.Message.setField(msg, 17, obj.supportedOrderReceiveKindsList);
  obj.shopId != null && jspb.Message.setField(msg, 18, obj.shopId);
  obj.healthDeptStatus != null && jspb.Message.setField(msg, 19, obj.healthDeptStatus);
  obj.availableReceiveMethodsList && jspb.Message.setRepeatedWrapperField(
      msg, 20, obj.availableReceiveMethodsList.map(
          type_receive_method_pb.ReceiveMethod.fromObject));
  obj.availableDeliveryOptionsList && jspb.Message.setRepeatedWrapperField(
      msg, 21, obj.availableDeliveryOptionsList.map(
          type_delivery_option_pb.DeliveryOption.fromObject));
  obj.availablePickupOptionsList && jspb.Message.setRepeatedWrapperField(
      msg, 22, obj.availablePickupOptionsList.map(
          type_pickup_option_pb.PickupOption.fromObject));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.EcSiteShop}
 */
proto.type.EcSiteShop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.EcSiteShop;
  return proto.type.EcSiteShop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.EcSiteShop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.EcSiteShop}
 */
proto.type.EcSiteShop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.type.EcSite;
      reader.readMessage(value,proto.type.EcSite.deserializeBinaryFromReader);
      msg.setEcSite(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {!proto.type.EcShopType.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {!proto.type.DeliveryType.Type} */ (reader.readEnum());
      msg.setDeliveryType(value);
      break;
    case 6:
      var value = new type_delivery_time_pb.DeliveryTime;
      reader.readMessage(value,type_delivery_time_pb.DeliveryTime.deserializeBinaryFromReader);
      msg.addDeliveryTimes(value);
      break;
    case 7:
      var value = new type_delivery_week_pb.DeliveryWeek;
      reader.readMessage(value,type_delivery_week_pb.DeliveryWeek.deserializeBinaryFromReader);
      msg.setDeliveryWeek(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeleted(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseExternalTransportManagementSystem(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalTransportManagementSystemUrl(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPractice(value);
      break;
    case 14:
      var value = new type_address_pb.Address;
      reader.readMessage(value,type_address_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addAllowedUserIds(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setReleasedAt(value);
      break;
    case 17:
      var values = /** @type {!Array<!proto.type.OrderReceiveKind>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSupportedOrderReceiveKinds(values[i]);
      }
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopId(value);
      break;
    case 19:
      var value = /** @type {!proto.type.HealthDeptStatus.Type} */ (reader.readEnum());
      msg.setHealthDeptStatus(value);
      break;
    case 20:
      var value = new type_receive_method_pb.ReceiveMethod;
      reader.readMessage(value,type_receive_method_pb.ReceiveMethod.deserializeBinaryFromReader);
      msg.addAvailableReceiveMethods(value);
      break;
    case 21:
      var value = new type_delivery_option_pb.DeliveryOption;
      reader.readMessage(value,type_delivery_option_pb.DeliveryOption.deserializeBinaryFromReader);
      msg.addAvailableDeliveryOptions(value);
      break;
    case 22:
      var value = new type_pickup_option_pb.PickupOption;
      reader.readMessage(value,type_pickup_option_pb.PickupOption.deserializeBinaryFromReader);
      msg.addAvailablePickupOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.EcSiteShop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.EcSiteShop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.EcSiteShop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.EcSiteShop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEcSite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.type.EcSite.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDeliveryType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getDeliveryTimesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      type_delivery_time_pb.DeliveryTime.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryWeek();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      type_delivery_week_pb.DeliveryWeek.serializeBinaryToWriter
    );
  }
  f = message.getIsDeleted();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUseExternalTransportManagementSystem();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getExternalTransportManagementSystemUrl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getIsPractice();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      type_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getAllowedUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
  f = message.getReleasedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSupportedOrderReceiveKindsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      17,
      f
    );
  }
  f = message.getShopId();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getHealthDeptStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getAvailableReceiveMethodsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      type_receive_method_pb.ReceiveMethod.serializeBinaryToWriter
    );
  }
  f = message.getAvailableDeliveryOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      type_delivery_option_pb.DeliveryOption.serializeBinaryToWriter
    );
  }
  f = message.getAvailablePickupOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      type_pickup_option_pb.PickupOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional EcSite ec_site = 1;
 * @return {?proto.type.EcSite}
 */
proto.type.EcSiteShop.prototype.getEcSite = function() {
  return /** @type{?proto.type.EcSite} */ (
    jspb.Message.getWrapperField(this, proto.type.EcSite, 1));
};


/**
 * @param {?proto.type.EcSite|undefined} value
 * @return {!proto.type.EcSiteShop} returns this
*/
proto.type.EcSiteShop.prototype.setEcSite = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.clearEcSite = function() {
  return this.setEcSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcSiteShop.prototype.hasEcSite = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.type.EcSiteShop.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.type.EcSiteShop.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional EcShopType.Type type = 4;
 * @return {!proto.type.EcShopType.Type}
 */
proto.type.EcSiteShop.prototype.getType = function() {
  return /** @type {!proto.type.EcShopType.Type} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.type.EcShopType.Type} value
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional DeliveryType.Type delivery_type = 5;
 * @return {!proto.type.DeliveryType.Type}
 */
proto.type.EcSiteShop.prototype.getDeliveryType = function() {
  return /** @type {!proto.type.DeliveryType.Type} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.type.DeliveryType.Type} value
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.setDeliveryType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated DeliveryTime delivery_times = 6;
 * @return {!Array<!proto.type.DeliveryTime>}
 */
proto.type.EcSiteShop.prototype.getDeliveryTimesList = function() {
  return /** @type{!Array<!proto.type.DeliveryTime>} */ (
    jspb.Message.getRepeatedWrapperField(this, type_delivery_time_pb.DeliveryTime, 6));
};


/**
 * @param {!Array<!proto.type.DeliveryTime>} value
 * @return {!proto.type.EcSiteShop} returns this
*/
proto.type.EcSiteShop.prototype.setDeliveryTimesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.type.DeliveryTime=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.DeliveryTime}
 */
proto.type.EcSiteShop.prototype.addDeliveryTimes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.type.DeliveryTime, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.clearDeliveryTimesList = function() {
  return this.setDeliveryTimesList([]);
};


/**
 * optional DeliveryWeek delivery_week = 7;
 * @return {?proto.type.DeliveryWeek}
 */
proto.type.EcSiteShop.prototype.getDeliveryWeek = function() {
  return /** @type{?proto.type.DeliveryWeek} */ (
    jspb.Message.getWrapperField(this, type_delivery_week_pb.DeliveryWeek, 7));
};


/**
 * @param {?proto.type.DeliveryWeek|undefined} value
 * @return {!proto.type.EcSiteShop} returns this
*/
proto.type.EcSiteShop.prototype.setDeliveryWeek = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.clearDeliveryWeek = function() {
  return this.setDeliveryWeek(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcSiteShop.prototype.hasDeliveryWeek = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool is_deleted = 8;
 * @return {boolean}
 */
proto.type.EcSiteShop.prototype.getIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.setIsDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string url = 9;
 * @return {string}
 */
proto.type.EcSiteShop.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool use_external_transport_management_system = 11;
 * @return {boolean}
 */
proto.type.EcSiteShop.prototype.getUseExternalTransportManagementSystem = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.setUseExternalTransportManagementSystem = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string external_transport_management_system_url = 12;
 * @return {string}
 */
proto.type.EcSiteShop.prototype.getExternalTransportManagementSystemUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.setExternalTransportManagementSystemUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool is_practice = 13;
 * @return {boolean}
 */
proto.type.EcSiteShop.prototype.getIsPractice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.setIsPractice = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional Address address = 14;
 * @return {?proto.type.Address}
 */
proto.type.EcSiteShop.prototype.getAddress = function() {
  return /** @type{?proto.type.Address} */ (
    jspb.Message.getWrapperField(this, type_address_pb.Address, 14));
};


/**
 * @param {?proto.type.Address|undefined} value
 * @return {!proto.type.EcSiteShop} returns this
*/
proto.type.EcSiteShop.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcSiteShop.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * repeated string allowed_user_ids = 15;
 * @return {!Array<string>}
 */
proto.type.EcSiteShop.prototype.getAllowedUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.setAllowedUserIdsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.addAllowedUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.clearAllowedUserIdsList = function() {
  return this.setAllowedUserIdsList([]);
};


/**
 * optional google.protobuf.Timestamp released_at = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.type.EcSiteShop.prototype.getReleasedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.type.EcSiteShop} returns this
*/
proto.type.EcSiteShop.prototype.setReleasedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.clearReleasedAt = function() {
  return this.setReleasedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcSiteShop.prototype.hasReleasedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * repeated OrderReceiveKind supported_order_receive_kinds = 17;
 * @return {!Array<!proto.type.OrderReceiveKind>}
 */
proto.type.EcSiteShop.prototype.getSupportedOrderReceiveKindsList = function() {
  return /** @type {!Array<!proto.type.OrderReceiveKind>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<!proto.type.OrderReceiveKind>} value
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.setSupportedOrderReceiveKindsList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {!proto.type.OrderReceiveKind} value
 * @param {number=} opt_index
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.addSupportedOrderReceiveKinds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.clearSupportedOrderReceiveKindsList = function() {
  return this.setSupportedOrderReceiveKindsList([]);
};


/**
 * optional string shop_id = 18;
 * @return {string}
 */
proto.type.EcSiteShop.prototype.getShopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.setShopId = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional HealthDeptStatus.Type health_dept_status = 19;
 * @return {!proto.type.HealthDeptStatus.Type}
 */
proto.type.EcSiteShop.prototype.getHealthDeptStatus = function() {
  return /** @type {!proto.type.HealthDeptStatus.Type} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.type.HealthDeptStatus.Type} value
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.setHealthDeptStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * repeated ReceiveMethod available_receive_methods = 20;
 * @return {!Array<!proto.type.ReceiveMethod>}
 */
proto.type.EcSiteShop.prototype.getAvailableReceiveMethodsList = function() {
  return /** @type{!Array<!proto.type.ReceiveMethod>} */ (
    jspb.Message.getRepeatedWrapperField(this, type_receive_method_pb.ReceiveMethod, 20));
};


/**
 * @param {!Array<!proto.type.ReceiveMethod>} value
 * @return {!proto.type.EcSiteShop} returns this
*/
proto.type.EcSiteShop.prototype.setAvailableReceiveMethodsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.type.ReceiveMethod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.ReceiveMethod}
 */
proto.type.EcSiteShop.prototype.addAvailableReceiveMethods = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.type.ReceiveMethod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.clearAvailableReceiveMethodsList = function() {
  return this.setAvailableReceiveMethodsList([]);
};


/**
 * repeated DeliveryOption available_delivery_options = 21;
 * @return {!Array<!proto.type.DeliveryOption>}
 */
proto.type.EcSiteShop.prototype.getAvailableDeliveryOptionsList = function() {
  return /** @type{!Array<!proto.type.DeliveryOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, type_delivery_option_pb.DeliveryOption, 21));
};


/**
 * @param {!Array<!proto.type.DeliveryOption>} value
 * @return {!proto.type.EcSiteShop} returns this
*/
proto.type.EcSiteShop.prototype.setAvailableDeliveryOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.type.DeliveryOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.DeliveryOption}
 */
proto.type.EcSiteShop.prototype.addAvailableDeliveryOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.type.DeliveryOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.clearAvailableDeliveryOptionsList = function() {
  return this.setAvailableDeliveryOptionsList([]);
};


/**
 * repeated PickupOption available_pickup_options = 22;
 * @return {!Array<!proto.type.PickupOption>}
 */
proto.type.EcSiteShop.prototype.getAvailablePickupOptionsList = function() {
  return /** @type{!Array<!proto.type.PickupOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, type_pickup_option_pb.PickupOption, 22));
};


/**
 * @param {!Array<!proto.type.PickupOption>} value
 * @return {!proto.type.EcSiteShop} returns this
*/
proto.type.EcSiteShop.prototype.setAvailablePickupOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.type.PickupOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.PickupOption}
 */
proto.type.EcSiteShop.prototype.addAvailablePickupOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.type.PickupOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.EcSiteShop} returns this
 */
proto.type.EcSiteShop.prototype.clearAvailablePickupOptionsList = function() {
  return this.setAvailablePickupOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.EcShopPickupType.prototype.toObject = function(opt_includeInstance) {
  return proto.type.EcShopPickupType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.EcShopPickupType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.EcShopPickupType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of EcShopPickupType as accepted by the `fromObject` method.
 * @record
 */
proto.type.EcShopPickupType.ObjectFormat = function() {
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.EcShopPickupType.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.EcShopPickupType}
 */
proto.type.EcShopPickupType.fromObject = function(obj) {
  var msg = new proto.type.EcShopPickupType();
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.EcShopPickupType}
 */
proto.type.EcShopPickupType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.EcShopPickupType;
  return proto.type.EcShopPickupType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.EcShopPickupType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.EcShopPickupType}
 */
proto.type.EcShopPickupType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.EcShopPickupType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.EcShopPickupType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.EcShopPickupType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.EcShopPickupType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.type.EcShopPickupType.Type = {
  STORE: 0,
  LOCKER: 1
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.type.EcSitePickupShop.repeatedFields_ = [25,27,28];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.EcSitePickupShop.prototype.toObject = function(opt_includeInstance) {
  return proto.type.EcSitePickupShop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.EcSitePickupShop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.EcSitePickupShop.toObject = function(includeInstance, msg) {
  var f, obj = {
    ecSite: (f = msg.getEcSite()) && proto.type.EcSite.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shopid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    receiptpointid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pickuptype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    isavailable: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isdeleted: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    name: jspb.Message.getFieldWithDefault(msg, 8, ""),
    address: jspb.Message.getFieldWithDefault(msg, 9, ""),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 12, ""),
    parkingAreaIamgeUrl: jspb.Message.getFieldWithDefault(msg, 13, ""),
    openingHour: jspb.Message.getFieldWithDefault(msg, 14, ""),
    closingHour: jspb.Message.getFieldWithDefault(msg, 15, ""),
    prefecture: jspb.Message.getFieldWithDefault(msg, 16, ""),
    sakeSalesManagementInfo: jspb.Message.getFieldWithDefault(msg, 17, ""),
    alcoholSalesManagementInfo: jspb.Message.getFieldWithDefault(msg, 18, ""),
    city: jspb.Message.getFieldWithDefault(msg, 19, ""),
    representative: jspb.Message.getFieldWithDefault(msg, 20, ""),
    availableTime: jspb.Message.getFieldWithDefault(msg, 21, ""),
    termsDocumentId: jspb.Message.getFieldWithDefault(msg, 22, ""),
    drugInfoDocumentId: jspb.Message.getFieldWithDefault(msg, 23, ""),
    businessHoursNote: jspb.Message.getFieldWithDefault(msg, 24, ""),
    allowedUserIdsList: (f = jspb.Message.getRepeatedField(msg, 25)) == null ? undefined : f,
    isPractice: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    supportedOrderReceiveKindsList: (f = jspb.Message.getRepeatedField(msg, 27)) == null ? undefined : f,
    availableReceiveMethodsList: jspb.Message.toObjectList(msg.getAvailableReceiveMethodsList(),
    type_receive_method_pb.ReceiveMethod.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of EcSitePickupShop as accepted by the `fromObject` method.
 * @record
 */
proto.type.EcSitePickupShop.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.ecSite;

  /** @type {?|undefined} */
  this.id;

  /** @type {?|undefined} */
  this.shopid;

  /** @type {?|undefined} */
  this.receiptpointid;

  /** @type {?|undefined} */
  this.pickuptype;

  /** @type {?|undefined} */
  this.isavailable;

  /** @type {?|undefined} */
  this.isdeleted;

  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.address;

  /** @type {?|undefined} */
  this.latitude;

  /** @type {?|undefined} */
  this.longitude;

  /** @type {?|undefined} */
  this.phoneNumber;

  /** @type {?|undefined} */
  this.parkingAreaIamgeUrl;

  /** @type {?|undefined} */
  this.openingHour;

  /** @type {?|undefined} */
  this.closingHour;

  /** @type {?|undefined} */
  this.prefecture;

  /** @type {?|undefined} */
  this.sakeSalesManagementInfo;

  /** @type {?|undefined} */
  this.alcoholSalesManagementInfo;

  /** @type {?|undefined} */
  this.city;

  /** @type {?|undefined} */
  this.representative;

  /** @type {?|undefined} */
  this.availableTime;

  /** @type {?|undefined} */
  this.termsDocumentId;

  /** @type {?|undefined} */
  this.drugInfoDocumentId;

  /** @type {?|undefined} */
  this.businessHoursNote;

  /** @type {?|undefined} */
  this.allowedUserIdsList;

  /** @type {?|undefined} */
  this.isPractice;

  /** @type {?|undefined} */
  this.supportedOrderReceiveKindsList;

  /** @type {?|undefined} */
  this.availableReceiveMethodsList;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.EcSitePickupShop.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.EcSitePickupShop}
 */
proto.type.EcSitePickupShop.fromObject = function(obj) {
  var msg = new proto.type.EcSitePickupShop();
  obj.ecSite && jspb.Message.setWrapperField(
      msg, 1, proto.type.EcSite.fromObject(obj.ecSite));
  obj.id != null && jspb.Message.setField(msg, 2, obj.id);
  obj.shopid != null && jspb.Message.setField(msg, 3, obj.shopid);
  obj.receiptpointid != null && jspb.Message.setField(msg, 4, obj.receiptpointid);
  obj.pickuptype != null && jspb.Message.setField(msg, 5, obj.pickuptype);
  obj.isavailable != null && jspb.Message.setField(msg, 6, obj.isavailable);
  obj.isdeleted != null && jspb.Message.setField(msg, 7, obj.isdeleted);
  obj.name != null && jspb.Message.setField(msg, 8, obj.name);
  obj.address != null && jspb.Message.setField(msg, 9, obj.address);
  obj.latitude != null && jspb.Message.setField(msg, 10, obj.latitude);
  obj.longitude != null && jspb.Message.setField(msg, 11, obj.longitude);
  obj.phoneNumber != null && jspb.Message.setField(msg, 12, obj.phoneNumber);
  obj.parkingAreaIamgeUrl != null && jspb.Message.setField(msg, 13, obj.parkingAreaIamgeUrl);
  obj.openingHour != null && jspb.Message.setField(msg, 14, obj.openingHour);
  obj.closingHour != null && jspb.Message.setField(msg, 15, obj.closingHour);
  obj.prefecture != null && jspb.Message.setField(msg, 16, obj.prefecture);
  obj.sakeSalesManagementInfo != null && jspb.Message.setField(msg, 17, obj.sakeSalesManagementInfo);
  obj.alcoholSalesManagementInfo != null && jspb.Message.setField(msg, 18, obj.alcoholSalesManagementInfo);
  obj.city != null && jspb.Message.setField(msg, 19, obj.city);
  obj.representative != null && jspb.Message.setField(msg, 20, obj.representative);
  obj.availableTime != null && jspb.Message.setField(msg, 21, obj.availableTime);
  obj.termsDocumentId != null && jspb.Message.setField(msg, 22, obj.termsDocumentId);
  obj.drugInfoDocumentId != null && jspb.Message.setField(msg, 23, obj.drugInfoDocumentId);
  obj.businessHoursNote != null && jspb.Message.setField(msg, 24, obj.businessHoursNote);
  obj.allowedUserIdsList != null && jspb.Message.setField(msg, 25, obj.allowedUserIdsList);
  obj.isPractice != null && jspb.Message.setField(msg, 26, obj.isPractice);
  obj.supportedOrderReceiveKindsList != null && jspb.Message.setField(msg, 27, obj.supportedOrderReceiveKindsList);
  obj.availableReceiveMethodsList && jspb.Message.setRepeatedWrapperField(
      msg, 28, obj.availableReceiveMethodsList.map(
          type_receive_method_pb.ReceiveMethod.fromObject));
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.EcSitePickupShop}
 */
proto.type.EcSitePickupShop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.EcSitePickupShop;
  return proto.type.EcSitePickupShop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.EcSitePickupShop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.EcSitePickupShop}
 */
proto.type.EcSitePickupShop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.type.EcSite;
      reader.readMessage(value,proto.type.EcSite.deserializeBinaryFromReader);
      msg.setEcSite(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiptpointid(value);
      break;
    case 5:
      var value = /** @type {!proto.type.EcShopPickupType.Type} */ (reader.readEnum());
      msg.setPickuptype(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsavailable(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsdeleted(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setParkingAreaIamgeUrl(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpeningHour(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setClosingHour(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrefecture(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setSakeSalesManagementInfo(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlcoholSalesManagementInfo(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepresentative(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvailableTime(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setTermsDocumentId(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrugInfoDocumentId(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessHoursNote(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.addAllowedUserIds(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPractice(value);
      break;
    case 27:
      var values = /** @type {!Array<!proto.type.OrderReceiveKind>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSupportedOrderReceiveKinds(values[i]);
      }
      break;
    case 28:
      var value = new type_receive_method_pb.ReceiveMethod;
      reader.readMessage(value,type_receive_method_pb.ReceiveMethod.deserializeBinaryFromReader);
      msg.addAvailableReceiveMethods(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.EcSitePickupShop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.EcSitePickupShop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.EcSitePickupShop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.EcSitePickupShop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEcSite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.type.EcSite.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShopid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReceiptpointid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPickuptype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getIsavailable();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsdeleted();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getParkingAreaIamgeUrl();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getOpeningHour();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getClosingHour();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPrefecture();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getSakeSalesManagementInfo();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getAlcoholSalesManagementInfo();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getRepresentative();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getAvailableTime();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getTermsDocumentId();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getDrugInfoDocumentId();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getBusinessHoursNote();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getAllowedUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      25,
      f
    );
  }
  f = message.getIsPractice();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getSupportedOrderReceiveKindsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      27,
      f
    );
  }
  f = message.getAvailableReceiveMethodsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      28,
      f,
      type_receive_method_pb.ReceiveMethod.serializeBinaryToWriter
    );
  }
};


/**
 * optional EcSite ec_site = 1;
 * @return {?proto.type.EcSite}
 */
proto.type.EcSitePickupShop.prototype.getEcSite = function() {
  return /** @type{?proto.type.EcSite} */ (
    jspb.Message.getWrapperField(this, proto.type.EcSite, 1));
};


/**
 * @param {?proto.type.EcSite|undefined} value
 * @return {!proto.type.EcSitePickupShop} returns this
*/
proto.type.EcSitePickupShop.prototype.setEcSite = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.clearEcSite = function() {
  return this.setEcSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcSitePickupShop.prototype.hasEcSite = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.type.EcSitePickupShop.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string shopId = 3;
 * @return {string}
 */
proto.type.EcSitePickupShop.prototype.getShopid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setShopid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string receiptPointId = 4;
 * @return {string}
 */
proto.type.EcSitePickupShop.prototype.getReceiptpointid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setReceiptpointid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional EcShopPickupType.Type pickupType = 5;
 * @return {!proto.type.EcShopPickupType.Type}
 */
proto.type.EcSitePickupShop.prototype.getPickuptype = function() {
  return /** @type {!proto.type.EcShopPickupType.Type} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.type.EcShopPickupType.Type} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setPickuptype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool isAvailable = 6;
 * @return {boolean}
 */
proto.type.EcSitePickupShop.prototype.getIsavailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setIsavailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool isDeleted = 7;
 * @return {boolean}
 */
proto.type.EcSitePickupShop.prototype.getIsdeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setIsdeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string name = 8;
 * @return {string}
 */
proto.type.EcSitePickupShop.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string address = 9;
 * @return {string}
 */
proto.type.EcSitePickupShop.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional double latitude = 10;
 * @return {number}
 */
proto.type.EcSitePickupShop.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double longitude = 11;
 * @return {number}
 */
proto.type.EcSitePickupShop.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional string phone_number = 12;
 * @return {string}
 */
proto.type.EcSitePickupShop.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string parking_area_iamge_url = 13;
 * @return {string}
 */
proto.type.EcSitePickupShop.prototype.getParkingAreaIamgeUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setParkingAreaIamgeUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string opening_hour = 14;
 * @return {string}
 */
proto.type.EcSitePickupShop.prototype.getOpeningHour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setOpeningHour = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string closing_hour = 15;
 * @return {string}
 */
proto.type.EcSitePickupShop.prototype.getClosingHour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setClosingHour = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string prefecture = 16;
 * @return {string}
 */
proto.type.EcSitePickupShop.prototype.getPrefecture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setPrefecture = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string sake_sales_management_info = 17;
 * @return {string}
 */
proto.type.EcSitePickupShop.prototype.getSakeSalesManagementInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setSakeSalesManagementInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string alcohol_sales_management_info = 18;
 * @return {string}
 */
proto.type.EcSitePickupShop.prototype.getAlcoholSalesManagementInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setAlcoholSalesManagementInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string city = 19;
 * @return {string}
 */
proto.type.EcSitePickupShop.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string representative = 20;
 * @return {string}
 */
proto.type.EcSitePickupShop.prototype.getRepresentative = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setRepresentative = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string available_time = 21;
 * @return {string}
 */
proto.type.EcSitePickupShop.prototype.getAvailableTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setAvailableTime = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string terms_document_id = 22;
 * @return {string}
 */
proto.type.EcSitePickupShop.prototype.getTermsDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setTermsDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string drug_info_document_id = 23;
 * @return {string}
 */
proto.type.EcSitePickupShop.prototype.getDrugInfoDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setDrugInfoDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string business_hours_note = 24;
 * @return {string}
 */
proto.type.EcSitePickupShop.prototype.getBusinessHoursNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setBusinessHoursNote = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * repeated string allowed_user_ids = 25;
 * @return {!Array<string>}
 */
proto.type.EcSitePickupShop.prototype.getAllowedUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 25));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setAllowedUserIdsList = function(value) {
  return jspb.Message.setField(this, 25, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.addAllowedUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 25, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.clearAllowedUserIdsList = function() {
  return this.setAllowedUserIdsList([]);
};


/**
 * optional bool is_practice = 26;
 * @return {boolean}
 */
proto.type.EcSitePickupShop.prototype.getIsPractice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setIsPractice = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * repeated OrderReceiveKind supported_order_receive_kinds = 27;
 * @return {!Array<!proto.type.OrderReceiveKind>}
 */
proto.type.EcSitePickupShop.prototype.getSupportedOrderReceiveKindsList = function() {
  return /** @type {!Array<!proto.type.OrderReceiveKind>} */ (jspb.Message.getRepeatedField(this, 27));
};


/**
 * @param {!Array<!proto.type.OrderReceiveKind>} value
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.setSupportedOrderReceiveKindsList = function(value) {
  return jspb.Message.setField(this, 27, value || []);
};


/**
 * @param {!proto.type.OrderReceiveKind} value
 * @param {number=} opt_index
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.addSupportedOrderReceiveKinds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 27, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.clearSupportedOrderReceiveKindsList = function() {
  return this.setSupportedOrderReceiveKindsList([]);
};


/**
 * repeated ReceiveMethod available_receive_methods = 28;
 * @return {!Array<!proto.type.ReceiveMethod>}
 */
proto.type.EcSitePickupShop.prototype.getAvailableReceiveMethodsList = function() {
  return /** @type{!Array<!proto.type.ReceiveMethod>} */ (
    jspb.Message.getRepeatedWrapperField(this, type_receive_method_pb.ReceiveMethod, 28));
};


/**
 * @param {!Array<!proto.type.ReceiveMethod>} value
 * @return {!proto.type.EcSitePickupShop} returns this
*/
proto.type.EcSitePickupShop.prototype.setAvailableReceiveMethodsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 28, value);
};


/**
 * @param {!proto.type.ReceiveMethod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.type.ReceiveMethod}
 */
proto.type.EcSitePickupShop.prototype.addAvailableReceiveMethods = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 28, opt_value, proto.type.ReceiveMethod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.EcSitePickupShop} returns this
 */
proto.type.EcSitePickupShop.prototype.clearAvailableReceiveMethodsList = function() {
  return this.setAvailableReceiveMethodsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.UnavailableEcSite.prototype.toObject = function(opt_includeInstance) {
  return proto.type.UnavailableEcSite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.UnavailableEcSite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.UnavailableEcSite.toObject = function(includeInstance, msg) {
  var f, obj = {
    ecSite: (f = msg.getEcSite()) && proto.type.EcSite.toObject(includeInstance, f),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    canSignIn: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    shopType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of UnavailableEcSite as accepted by the `fromObject` method.
 * @record
 */
proto.type.UnavailableEcSite.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.ecSite;

  /** @type {?|undefined} */
  this.message;

  /** @type {?|undefined} */
  this.canSignIn;

  /** @type {?|undefined} */
  this.shopType;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.UnavailableEcSite.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.UnavailableEcSite}
 */
proto.type.UnavailableEcSite.fromObject = function(obj) {
  var msg = new proto.type.UnavailableEcSite();
  obj.ecSite && jspb.Message.setWrapperField(
      msg, 1, proto.type.EcSite.fromObject(obj.ecSite));
  obj.message != null && jspb.Message.setField(msg, 2, obj.message);
  obj.canSignIn != null && jspb.Message.setField(msg, 3, obj.canSignIn);
  obj.shopType != null && jspb.Message.setField(msg, 4, obj.shopType);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.UnavailableEcSite}
 */
proto.type.UnavailableEcSite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.UnavailableEcSite;
  return proto.type.UnavailableEcSite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.UnavailableEcSite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.UnavailableEcSite}
 */
proto.type.UnavailableEcSite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.type.EcSite;
      reader.readMessage(value,proto.type.EcSite.deserializeBinaryFromReader);
      msg.setEcSite(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanSignIn(value);
      break;
    case 4:
      var value = /** @type {!proto.type.EcShopType.Type} */ (reader.readEnum());
      msg.setShopType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.UnavailableEcSite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.UnavailableEcSite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.UnavailableEcSite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.UnavailableEcSite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEcSite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.type.EcSite.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCanSignIn();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getShopType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional EcSite ec_site = 1;
 * @return {?proto.type.EcSite}
 */
proto.type.UnavailableEcSite.prototype.getEcSite = function() {
  return /** @type{?proto.type.EcSite} */ (
    jspb.Message.getWrapperField(this, proto.type.EcSite, 1));
};


/**
 * @param {?proto.type.EcSite|undefined} value
 * @return {!proto.type.UnavailableEcSite} returns this
*/
proto.type.UnavailableEcSite.prototype.setEcSite = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.UnavailableEcSite} returns this
 */
proto.type.UnavailableEcSite.prototype.clearEcSite = function() {
  return this.setEcSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.UnavailableEcSite.prototype.hasEcSite = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.type.UnavailableEcSite.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.UnavailableEcSite} returns this
 */
proto.type.UnavailableEcSite.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool can_sign_in = 3;
 * @return {boolean}
 */
proto.type.UnavailableEcSite.prototype.getCanSignIn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.type.UnavailableEcSite} returns this
 */
proto.type.UnavailableEcSite.prototype.setCanSignIn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional EcShopType.Type shop_type = 4;
 * @return {!proto.type.EcShopType.Type}
 */
proto.type.UnavailableEcSite.prototype.getShopType = function() {
  return /** @type {!proto.type.EcShopType.Type} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.type.EcShopType.Type} value
 * @return {!proto.type.UnavailableEcSite} returns this
 */
proto.type.UnavailableEcSite.prototype.setShopType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.EcSiteShopDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.type.EcSiteShopDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.EcSiteShopDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.EcSiteShopDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    ecSiteShopId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: jspb.Message.getFieldWithDefault(msg, 3, ""),
    openingHour: jspb.Message.getFieldWithDefault(msg, 4, ""),
    closingHour: jspb.Message.getFieldWithDefault(msg, 5, ""),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    pickupShopInformation: (f = msg.getPickupShopInformation()) && proto.type.PickupShopInformation.toObject(includeInstance, f),
    pickupCarInformation: (f = msg.getPickupCarInformation()) && proto.type.PickupCarInformation.toObject(includeInstance, f),
    pickupLockerInformation: (f = msg.getPickupLockerInformation()) && proto.type.PickupLockerInformation.toObject(includeInstance, f),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of EcSiteShopDetail as accepted by the `fromObject` method.
 * @record
 */
proto.type.EcSiteShopDetail.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.ecSiteShopId;

  /** @type {?|undefined} */
  this.name;

  /** @type {?|undefined} */
  this.address;

  /** @type {?|undefined} */
  this.openingHour;

  /** @type {?|undefined} */
  this.closingHour;

  /** @type {?|undefined} */
  this.latitude;

  /** @type {?|undefined} */
  this.longitude;

  /** @type {?|undefined} */
  this.pickupShopInformation;

  /** @type {?|undefined} */
  this.pickupCarInformation;

  /** @type {?|undefined} */
  this.pickupLockerInformation;

  /** @type {?|undefined} */
  this.phoneNumber;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.EcSiteShopDetail.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.EcSiteShopDetail}
 */
proto.type.EcSiteShopDetail.fromObject = function(obj) {
  var msg = new proto.type.EcSiteShopDetail();
  obj.ecSiteShopId != null && jspb.Message.setField(msg, 1, obj.ecSiteShopId);
  obj.name != null && jspb.Message.setField(msg, 2, obj.name);
  obj.address != null && jspb.Message.setField(msg, 3, obj.address);
  obj.openingHour != null && jspb.Message.setField(msg, 4, obj.openingHour);
  obj.closingHour != null && jspb.Message.setField(msg, 5, obj.closingHour);
  obj.latitude != null && jspb.Message.setField(msg, 6, obj.latitude);
  obj.longitude != null && jspb.Message.setField(msg, 7, obj.longitude);
  obj.pickupShopInformation && jspb.Message.setWrapperField(
      msg, 8, proto.type.PickupShopInformation.fromObject(obj.pickupShopInformation));
  obj.pickupCarInformation && jspb.Message.setWrapperField(
      msg, 9, proto.type.PickupCarInformation.fromObject(obj.pickupCarInformation));
  obj.pickupLockerInformation && jspb.Message.setWrapperField(
      msg, 10, proto.type.PickupLockerInformation.fromObject(obj.pickupLockerInformation));
  obj.phoneNumber != null && jspb.Message.setField(msg, 11, obj.phoneNumber);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.EcSiteShopDetail}
 */
proto.type.EcSiteShopDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.EcSiteShopDetail;
  return proto.type.EcSiteShopDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.EcSiteShopDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.EcSiteShopDetail}
 */
proto.type.EcSiteShopDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEcSiteShopId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpeningHour(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setClosingHour(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 8:
      var value = new proto.type.PickupShopInformation;
      reader.readMessage(value,proto.type.PickupShopInformation.deserializeBinaryFromReader);
      msg.setPickupShopInformation(value);
      break;
    case 9:
      var value = new proto.type.PickupCarInformation;
      reader.readMessage(value,proto.type.PickupCarInformation.deserializeBinaryFromReader);
      msg.setPickupCarInformation(value);
      break;
    case 10:
      var value = new proto.type.PickupLockerInformation;
      reader.readMessage(value,proto.type.PickupLockerInformation.deserializeBinaryFromReader);
      msg.setPickupLockerInformation(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.EcSiteShopDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.EcSiteShopDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.EcSiteShopDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.EcSiteShopDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEcSiteShopId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOpeningHour();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClosingHour();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getPickupShopInformation();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.type.PickupShopInformation.serializeBinaryToWriter
    );
  }
  f = message.getPickupCarInformation();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.type.PickupCarInformation.serializeBinaryToWriter
    );
  }
  f = message.getPickupLockerInformation();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.type.PickupLockerInformation.serializeBinaryToWriter
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string ec_site_shop_id = 1;
 * @return {string}
 */
proto.type.EcSiteShopDetail.prototype.getEcSiteShopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSiteShopDetail} returns this
 */
proto.type.EcSiteShopDetail.prototype.setEcSiteShopId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.type.EcSiteShopDetail.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSiteShopDetail} returns this
 */
proto.type.EcSiteShopDetail.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address = 3;
 * @return {string}
 */
proto.type.EcSiteShopDetail.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSiteShopDetail} returns this
 */
proto.type.EcSiteShopDetail.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string opening_hour = 4;
 * @return {string}
 */
proto.type.EcSiteShopDetail.prototype.getOpeningHour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSiteShopDetail} returns this
 */
proto.type.EcSiteShopDetail.prototype.setOpeningHour = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string closing_hour = 5;
 * @return {string}
 */
proto.type.EcSiteShopDetail.prototype.getClosingHour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSiteShopDetail} returns this
 */
proto.type.EcSiteShopDetail.prototype.setClosingHour = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double latitude = 6;
 * @return {number}
 */
proto.type.EcSiteShopDetail.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.EcSiteShopDetail} returns this
 */
proto.type.EcSiteShopDetail.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double longitude = 7;
 * @return {number}
 */
proto.type.EcSiteShopDetail.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.type.EcSiteShopDetail} returns this
 */
proto.type.EcSiteShopDetail.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional PickupShopInformation pickup_shop_information = 8;
 * @return {?proto.type.PickupShopInformation}
 */
proto.type.EcSiteShopDetail.prototype.getPickupShopInformation = function() {
  return /** @type{?proto.type.PickupShopInformation} */ (
    jspb.Message.getWrapperField(this, proto.type.PickupShopInformation, 8));
};


/**
 * @param {?proto.type.PickupShopInformation|undefined} value
 * @return {!proto.type.EcSiteShopDetail} returns this
*/
proto.type.EcSiteShopDetail.prototype.setPickupShopInformation = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcSiteShopDetail} returns this
 */
proto.type.EcSiteShopDetail.prototype.clearPickupShopInformation = function() {
  return this.setPickupShopInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcSiteShopDetail.prototype.hasPickupShopInformation = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional PickupCarInformation pickup_car_information = 9;
 * @return {?proto.type.PickupCarInformation}
 */
proto.type.EcSiteShopDetail.prototype.getPickupCarInformation = function() {
  return /** @type{?proto.type.PickupCarInformation} */ (
    jspb.Message.getWrapperField(this, proto.type.PickupCarInformation, 9));
};


/**
 * @param {?proto.type.PickupCarInformation|undefined} value
 * @return {!proto.type.EcSiteShopDetail} returns this
*/
proto.type.EcSiteShopDetail.prototype.setPickupCarInformation = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcSiteShopDetail} returns this
 */
proto.type.EcSiteShopDetail.prototype.clearPickupCarInformation = function() {
  return this.setPickupCarInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcSiteShopDetail.prototype.hasPickupCarInformation = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional PickupLockerInformation pickup_locker_information = 10;
 * @return {?proto.type.PickupLockerInformation}
 */
proto.type.EcSiteShopDetail.prototype.getPickupLockerInformation = function() {
  return /** @type{?proto.type.PickupLockerInformation} */ (
    jspb.Message.getWrapperField(this, proto.type.PickupLockerInformation, 10));
};


/**
 * @param {?proto.type.PickupLockerInformation|undefined} value
 * @return {!proto.type.EcSiteShopDetail} returns this
*/
proto.type.EcSiteShopDetail.prototype.setPickupLockerInformation = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.type.EcSiteShopDetail} returns this
 */
proto.type.EcSiteShopDetail.prototype.clearPickupLockerInformation = function() {
  return this.setPickupLockerInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.type.EcSiteShopDetail.prototype.hasPickupLockerInformation = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string phone_number = 11;
 * @return {string}
 */
proto.type.EcSiteShopDetail.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.EcSiteShopDetail} returns this
 */
proto.type.EcSiteShopDetail.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.type.PickupShopInformation.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.PickupShopInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.type.PickupShopInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.PickupShopInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.PickupShopInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pickupLocationImagesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of PickupShopInformation as accepted by the `fromObject` method.
 * @record
 */
proto.type.PickupShopInformation.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.description;

  /** @type {?|undefined} */
  this.pickupLocationImagesList;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.PickupShopInformation.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.PickupShopInformation}
 */
proto.type.PickupShopInformation.fromObject = function(obj) {
  var msg = new proto.type.PickupShopInformation();
  obj.description != null && jspb.Message.setField(msg, 1, obj.description);
  obj.pickupLocationImagesList != null && jspb.Message.setField(msg, 2, obj.pickupLocationImagesList);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.PickupShopInformation}
 */
proto.type.PickupShopInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.PickupShopInformation;
  return proto.type.PickupShopInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.PickupShopInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.PickupShopInformation}
 */
proto.type.PickupShopInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addPickupLocationImages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.PickupShopInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.PickupShopInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.PickupShopInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.PickupShopInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPickupLocationImagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.type.PickupShopInformation.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.PickupShopInformation} returns this
 */
proto.type.PickupShopInformation.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string pickup_location_images = 2;
 * @return {!Array<string>}
 */
proto.type.PickupShopInformation.prototype.getPickupLocationImagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.type.PickupShopInformation} returns this
 */
proto.type.PickupShopInformation.prototype.setPickupLocationImagesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.type.PickupShopInformation} returns this
 */
proto.type.PickupShopInformation.prototype.addPickupLocationImages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.PickupShopInformation} returns this
 */
proto.type.PickupShopInformation.prototype.clearPickupLocationImagesList = function() {
  return this.setPickupLocationImagesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.type.PickupCarInformation.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.PickupCarInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.type.PickupCarInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.PickupCarInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.PickupCarInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pickupLocationImagesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of PickupCarInformation as accepted by the `fromObject` method.
 * @record
 */
proto.type.PickupCarInformation.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.description;

  /** @type {?|undefined} */
  this.pickupLocationImagesList;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.PickupCarInformation.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.PickupCarInformation}
 */
proto.type.PickupCarInformation.fromObject = function(obj) {
  var msg = new proto.type.PickupCarInformation();
  obj.description != null && jspb.Message.setField(msg, 1, obj.description);
  obj.pickupLocationImagesList != null && jspb.Message.setField(msg, 2, obj.pickupLocationImagesList);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.PickupCarInformation}
 */
proto.type.PickupCarInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.PickupCarInformation;
  return proto.type.PickupCarInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.PickupCarInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.PickupCarInformation}
 */
proto.type.PickupCarInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addPickupLocationImages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.PickupCarInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.PickupCarInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.PickupCarInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.PickupCarInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPickupLocationImagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.type.PickupCarInformation.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.PickupCarInformation} returns this
 */
proto.type.PickupCarInformation.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string pickup_location_images = 2;
 * @return {!Array<string>}
 */
proto.type.PickupCarInformation.prototype.getPickupLocationImagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.type.PickupCarInformation} returns this
 */
proto.type.PickupCarInformation.prototype.setPickupLocationImagesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.type.PickupCarInformation} returns this
 */
proto.type.PickupCarInformation.prototype.addPickupLocationImages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.PickupCarInformation} returns this
 */
proto.type.PickupCarInformation.prototype.clearPickupLocationImagesList = function() {
  return this.setPickupLocationImagesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.type.PickupLockerInformation.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.type.PickupLockerInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.type.PickupLockerInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.type.PickupLockerInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.PickupLockerInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pickupLocationImagesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


if (jspb.Message.GENERATE_FROM_OBJECT) {

/**
 * The raw object form of PickupLockerInformation as accepted by the `fromObject` method.
 * @record
 */
proto.type.PickupLockerInformation.ObjectFormat = function() {
  /** @type {?|undefined} */
  this.description;

  /** @type {?|undefined} */
  this.pickupLocationImagesList;
};

/**
 * Loads data from an object into a new instance of this proto.
 * @param {!proto.type.PickupLockerInformation.ObjectFormat} obj
 *     The object representation of this proto to load the data from.
 * @return {!proto.type.PickupLockerInformation}
 */
proto.type.PickupLockerInformation.fromObject = function(obj) {
  var msg = new proto.type.PickupLockerInformation();
  obj.description != null && jspb.Message.setField(msg, 1, obj.description);
  obj.pickupLocationImagesList != null && jspb.Message.setField(msg, 2, obj.pickupLocationImagesList);
  return msg;
};
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.type.PickupLockerInformation}
 */
proto.type.PickupLockerInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.type.PickupLockerInformation;
  return proto.type.PickupLockerInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.type.PickupLockerInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.type.PickupLockerInformation}
 */
proto.type.PickupLockerInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addPickupLocationImages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.type.PickupLockerInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.type.PickupLockerInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.type.PickupLockerInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.type.PickupLockerInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPickupLocationImagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.type.PickupLockerInformation.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.type.PickupLockerInformation} returns this
 */
proto.type.PickupLockerInformation.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string pickup_location_images = 2;
 * @return {!Array<string>}
 */
proto.type.PickupLockerInformation.prototype.getPickupLocationImagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.type.PickupLockerInformation} returns this
 */
proto.type.PickupLockerInformation.prototype.setPickupLocationImagesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.type.PickupLockerInformation} returns this
 */
proto.type.PickupLockerInformation.prototype.addPickupLocationImages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.type.PickupLockerInformation} returns this
 */
proto.type.PickupLockerInformation.prototype.clearPickupLocationImagesList = function() {
  return this.setPickupLocationImagesList([]);
};


/**
 * @enum {number}
 */
proto.type.LifeShopRegion = {
  LIFE_SHOP_REGION_UNSPECIFIED: 0,
  LIFE_SHOP_REGION_KINKI: 1,
  LIFE_SHOP_REGION_SHUTOKEN: 2
};

goog.object.extend(exports, proto.type);
