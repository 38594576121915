import { EcSite } from '@apis/type/ec_site_pb'

export enum UploadImageNameType {
  unsupported, // 画像アップロード非対応
  janCodeLife, // ex) A00002720001510.jpg JANに文字列が含まれる場合がある
  productIdAndOrder, // ex) 17800174527-0.jpg, 17800174527.jpg
}

export type ProductCodeDisplayType = 'none' | 'scanCode' | 'productId'
export type ProductCodePosition = 'productNameColumn' | 'productCodeColumn'
export type SalesBadge = {
  value: number
  label: String
}

export enum ReceiptComment {
  Alchol = '20歳未満の酒類の販売は固くお断りしております。',
  SelfMedication = '●印を記した商品はセルフメディケーション税制対象商品です。',
  BundleMixMatch = '■印を記した商品はまとめ割・よりどり割適用商品です。',
  ReducedTaxRate = '※ [外税8%]または[内税8%]と記載の商品は軽減税率適用商品です。',
  BankAccountTransfer = '※ お支払い方法が口座振替のお客様は、一ヶ月分まとめて引落しになります。',
  Point = '※ ポイント加算はお届け日の1〜2日後です。',
  ContactInfo = 'お問い合わせ先：ネットスーパーアプリまたはWebサイトのお問い合わせフォームよりご連絡ください。',
}

interface FeatureFlags {
  // 代引き手数料を有効にするかどうか
  enableCashOnDeliveryFee: boolean
  // 酒類販売管理者標識を管理画面で編集できるかどうか
  // 商品マスタで酒類販売管理者標識を管理する場合はfalseにして管理画面で編集できなくする
  // stailer-serverのkeepExisitngAlcoholSalesManagementInfoと対応する
  enableEditAlcoholSalesManagementInfo: boolean
  // Stailer標準の売上連携を使うかどうか
  enableStailerStandardSalesFinalizer: boolean
  // ピッキング伝票で選択するデフォルトの日付を前日ピッキングのために翌日にする
  preferDayBeforePicking: boolean
  // 注文詳細に受け渡し日の変更を表示するかどうか	(ライフのみ)管理画面の注文を別日に移せる機能。
  // 商品金額変わったりする課題が整理されていないので、まだ使いたくない。再配達のときは使わない
  enableChangeDeliveryTime: boolean
  // ピッキング伝票にバーコードを表示するかどうか
  displayBarcodeInPickList: boolean
  // ピッキング伝票にproductCodeを表示するかどうか
  displayProductCodeInPickList: boolean
  // ピッキング伝票に表示するproductCodeの種別
  productCodeDisplayTypeInPickList: ProductCodeDisplayType
  // ピッキング伝票に表示するproductCodeの位置
  productCodePositionInPickList: ProductCodePosition
  // ピッキング伝票にproductCodeを表示する場合に下何桁までを強調表示するか
  productCodeEmphasizedDigitInPickList: number
  // ライフの使い放題チケットのように特定の期間有効な商品があるかどうか
  // コード的にはstailer-serverのSubscriptionクラスを使用しているかどうか
  enableSubscription: boolean
  // サブスクリプション機能が有効かどうか
  enableSubscriptionV2: boolean
  // 薬王堂のように受け取り枠を選択する場面でorderReceiveKind.PICKUP_CARがPICKUP_SHOP,
  // PICKUP_LOCKERをかねている場合
  // https://github.com/10xinc/stailer-client/pull/2766/files#diff-0fa118efe63130f3d7a3d94488906c6c3633c31d7c37293dc361a5f406726746R202
  // internal-adminで定義されている、shareSameDeliveryTimeForPickupEcSitesという変数に
  // このフラグが立っているSiteTypeがハードコーディングされているので有効にする時はそちらも確認する
  shareSameDeliveryTimeForPickup: boolean
  // ポイントの分割表示をするか（ライフはボーナスポイント、通常ポイントがある）
  isDisplaySplitEarnedPoint: boolean
  // ポイント連携をしているか
  enableLinkPointInfo: boolean
  // ポイント利用ができるかどうか
  enableRedeemPoint: boolean
  // 来店宅配
  useInStoreArrangedDelivery: boolean
  // 代替品のピッキングを有効にするか
  useAlternativePicking: boolean
  // 問い合わせの管理機能の何を使うか（閲覧のみ、ステータス変更機能）
  // refs https://github.com/10xinc/stailer/issues/6816
  contactManagementType: 'viewOnly' | 'statusControl'
  // 注文詳細画面に商品追加への導線を表示するかどうか
  displayLinkToNewProductInOrder: boolean
  // 納品書に獲得予定ポイントを表示するかどうか
  displayEarnedPointInReceipt: boolean
  // バンドルミックスマッチ機能を表示するか
  showBundleMixmatch: boolean
  // 複数マスタアップロード機能が有効かどうか
  enableMultipleMasterDataUpload: boolean
  // 補償手続きを有効にするかどうか
  enableCompensationClame: boolean
  // StailerTMSを有効にするかどうか
  enableStailerTMS: boolean
  // 予約商品機能を有効にするかどうか
  enableReservationProduct: boolean
  // 口座振替機能を有効にするかどうか
  enableBankAccountTransfer: boolean
  // カタログ機能を有効にするかどうか
  enableCatalog: boolean
  // 仕入れ機能を有効にするかどうか
  enableProcurement: boolean
  // 配送表印刷機能（紙OpsのTMS）を有効にするかどうか
  enableDeliveryVouchers: boolean
  // 定期便機能を有効にするかどうか
  enableRecurringOrder: boolean
  // 発信履歴機能を有効にするかどうか
  enableOutgoingCallHistory: boolean
  // お問い合わせ表示を有効にするかどうか
  enableContants: boolean
}

export interface PartnerConfig {
  featureFlags: FeatureFlags
  // アップロードする画像名の種別。新規は原則productIdAndOrderでよさそう。
  // unsupported, // 画像アップロード非対応
  // janCodeLife, // ex) A00002720001510.jpg JANに文字列が含まれる場合がある
  // productIdAndOrder, // ex) 17800174527-0.jpg, 17800174527.jpg
  uploadImageNameType: UploadImageNameType
  // 最後の操作からログアウトするまでの時間。プラットフォームの仕様としては原則3時間
  sessionTimeoutMs: number
  // ヤマトの配送システムを利用する店舗リスト
  useYamatoTransportServiceShopIds: Array<String>
  // 対応する販促企画。stailer-serverのsite_config#salesBadgesに対応。
  salesBadges: Array<SalesBadge>
  // 空文字にすると指定した注釈コメントを表示しない
  customReceiptComments: Map<ReceiptComment, string> | null
}

// &で接続した複数のObject型をマージするユーティリティ型
type Merge<T> = { [K in keyof T]: T[K] }
// PartnerConfig直下のプロパティとFeatureFlagsのプロパティをOptionalにした型
type OptionalPartnerConfig = Merge<
  { featureFlags: Partial<FeatureFlags> } & Partial<Omit<PartnerConfig, 'featureFlags'>>
>

// default valueを使ってPartnerConfigを作成する
const createPartnerConfig = (c: OptionalPartnerConfig): PartnerConfig => {
  const f = c.featureFlags
  return {
    featureFlags: {
      enableCashOnDeliveryFee: f.enableCashOnDeliveryFee ?? false,
      enableEditAlcoholSalesManagementInfo: f.enableEditAlcoholSalesManagementInfo ?? false,
      enableSubscription: f.enableSubscription ?? false,
      enableSubscriptionV2: f.enableSubscriptionV2 ?? false,
      enableStailerStandardSalesFinalizer: f.enableStailerStandardSalesFinalizer ?? false,
      preferDayBeforePicking: f.preferDayBeforePicking ?? false,
      enableChangeDeliveryTime: f.enableChangeDeliveryTime ?? false,
      shareSameDeliveryTimeForPickup: f.shareSameDeliveryTimeForPickup ?? false,
      displayBarcodeInPickList: f.displayBarcodeInPickList ?? false,
      displayProductCodeInPickList: f.displayProductCodeInPickList ?? false,
      productCodeDisplayTypeInPickList: f.productCodeDisplayTypeInPickList ?? 'none',
      productCodeEmphasizedDigitInPickList: f.productCodeEmphasizedDigitInPickList ?? 0,
      productCodePositionInPickList: f.productCodePositionInPickList ?? 'productNameColumn',
      isDisplaySplitEarnedPoint: f.isDisplaySplitEarnedPoint ?? false,
      enableLinkPointInfo: f.enableLinkPointInfo ?? false,
      enableRedeemPoint: f.enableRedeemPoint ?? false,
      useInStoreArrangedDelivery: f.useInStoreArrangedDelivery ?? false,
      useAlternativePicking: f.useAlternativePicking ?? false,
      contactManagementType: f.contactManagementType ?? 'viewOnly',
      displayLinkToNewProductInOrder: f.displayLinkToNewProductInOrder ?? false,
      displayEarnedPointInReceipt: f.displayEarnedPointInReceipt ?? false,
      showBundleMixmatch: f.showBundleMixmatch ?? false,
      enableCompensationClame: f.enableCompensationClame ?? false,
      enableMultipleMasterDataUpload: f.enableMultipleMasterDataUpload ?? false,
      enableStailerTMS: f.enableStailerTMS ?? false,
      enableReservationProduct: f.enableReservationProduct ?? false,
      enableBankAccountTransfer: f.enableBankAccountTransfer ?? false,
      enableCatalog: f.enableCatalog ?? false,
      enableProcurement: f.enableProcurement ?? false,
      enableDeliveryVouchers: f.enableDeliveryVouchers ?? false,
      enableRecurringOrder: f.enableRecurringOrder ?? false,
      enableOutgoingCallHistory: f.enableOutgoingCallHistory ?? false,
      enableContants: f.enableContants ?? false,
    },
    uploadImageNameType: c.uploadImageNameType ?? UploadImageNameType.unsupported,
    sessionTimeoutMs: c.sessionTimeoutMs ?? 3 * 60 * 60 * 1000,
    useYamatoTransportServiceShopIds: c.useYamatoTransportServiceShopIds ?? [],
    salesBadges: c.salesBadges ?? [{ value: 0, label: 'なし' }],
    customReceiptComments: c.customReceiptComments ?? null,
  }
}

export function getPartnerConfig(siteType: EcSite.SiteType): PartnerConfig {
  switch (siteType) {
    case EcSite.SiteType.FRESTA: {
      return createPartnerConfig({
        featureFlags: {
          enableSubscriptionV2: true,
          enableLinkPointInfo: true,
          displayEarnedPointInReceipt: true,
          enableCompensationClame: true,
          enableMultipleMasterDataUpload: true,
          enableReservationProduct: true,
          enableBankAccountTransfer: true,
          enableCatalog: true,
          enableProcurement: true,
          enableDeliveryVouchers: true,
          enableRecurringOrder: true,
        },
        uploadImageNameType: UploadImageNameType.productIdAndOrder,
        salesBadges: [
          { value: 0, label: 'なし' },
          { value: 2, label: '日替わり' },
          { value: 3, label: '月安' },
        ],
      })
    }
    case EcSite.SiteType.LIFE: {
      return createPartnerConfig({
        featureFlags: {
          enableEditAlcoholSalesManagementInfo: true,
          enableSubscription: true,
          enableChangeDeliveryTime: true,
          displayProductCodeInPickList: true,
          productCodeDisplayTypeInPickList: 'scanCode',
          productCodeEmphasizedDigitInPickList: 4,
          isDisplaySplitEarnedPoint: true,
          enableLinkPointInfo: true,
          enableRedeemPoint: true,
          useInStoreArrangedDelivery: true,
          useAlternativePicking: true,
          contactManagementType: 'statusControl',
          displayLinkToNewProductInOrder: true,
          displayEarnedPointInReceipt: true,
          showBundleMixmatch: true,
          enableStailerTMS: true,
          enableOutgoingCallHistory: true,
          enableContants: true,
        },
        uploadImageNameType: UploadImageNameType.janCodeLife,
        sessionTimeoutMs: 8 * 60 * 60 * 1000,
        salesBadges: [
          { value: 0, label: 'なし' },
          { value: 3, label: '定番' },
          { value: 4, label: '日替わり' },
          { value: 5, label: '広告の品' },
          { value: 6, label: 'おすすめ' },
        ],
      })
    }
    case EcSite.SiteType.DEMO: {
      return createPartnerConfig({
        featureFlags: {
          enableEditAlcoholSalesManagementInfo: true,
          enableStailerStandardSalesFinalizer: true,
          shareSameDeliveryTimeForPickup: true,
          displayBarcodeInPickList: true,
          enableLinkPointInfo: true,
          useAlternativePicking: true,
          displayLinkToNewProductInOrder: true,
          displayEarnedPointInReceipt: true,
          enableStailerTMS: true,
          enableOutgoingCallHistory: true,
          enableContants: true,
        },
        uploadImageNameType: UploadImageNameType.productIdAndOrder,
      })
    }
    case EcSite.SiteType.ALBIS: {
      return createPartnerConfig({
        featureFlags: {
          shareSameDeliveryTimeForPickup: true,
          displayBarcodeInPickList: true,
          productCodeDisplayTypeInPickList: 'scanCode',
          productCodeEmphasizedDigitInPickList: 3,
          enableLinkPointInfo: true,
          displayEarnedPointInReceipt: true,
          enableStailerTMS: true,
          enableContants: true,
        },
        uploadImageNameType: UploadImageNameType.productIdAndOrder,
      })
    }
    case EcSite.SiteType.YAKUODO: {
      return createPartnerConfig({
        featureFlags: {
          shareSameDeliveryTimeForPickup: true,
          displayBarcodeInPickList: true,
          productCodeDisplayTypeInPickList: 'productId',
          productCodeEmphasizedDigitInPickList: 3,
          enableLinkPointInfo: true,
          displayLinkToNewProductInOrder: true,
          displayEarnedPointInReceipt: true,
          enableStailerTMS: true,
          enableOutgoingCallHistory: true,
          enableContants: true,
        },
        uploadImageNameType: UploadImageNameType.productIdAndOrder,
        customReceiptComments: new Map([
          [
            ReceiptComment.ContactInfo,
            'お問い合わせ先：P!ck andのお問い合わせフォームよりご連絡ください。',
          ],
        ]),
      })
    }
    case EcSite.SiteType.HEIWADO: {
      return createPartnerConfig({
        featureFlags: {
          enableStailerStandardSalesFinalizer: true,
          displayBarcodeInPickList: true,
          enableLinkPointInfo: true,
          useAlternativePicking: true,
          displayLinkToNewProductInOrder: true,
          enableStailerTMS: true,
          enableContants: true,
        },
        uploadImageNameType: UploadImageNameType.productIdAndOrder,
        salesBadges: [
          { value: 0, label: 'なし' },
          { value: 1, label: 'おすすめ' },
          { value: 2, label: 'ハトオシ' },
        ],
        customReceiptComments: new Map([
          [
            ReceiptComment.ContactInfo,
            'お問い合わせ先：商品についてはご購入店舗へ、アプリの不具合等についてはネットスーパーアプリお問い合わせフォームよりご連絡ください。',
          ],
        ]),
      })
    }
    case EcSite.SiteType.SUGI: {
      return createPartnerConfig({
        featureFlags: {
          enableStailerStandardSalesFinalizer: true,
          shareSameDeliveryTimeForPickup: true,
          displayBarcodeInPickList: true,
          useAlternativePicking: true,
          productCodeDisplayTypeInPickList: 'scanCode',
          productCodeEmphasizedDigitInPickList: 4,
          displayLinkToNewProductInOrder: true,
          displayEarnedPointInReceipt: true,
          enableStailerTMS: true,
          enableContants: true,
        },
        uploadImageNameType: UploadImageNameType.productIdAndOrder,
        salesBadges: [
          { value: 0, label: 'なし' },
          { value: 1, label: '期間特売' },
          { value: 2, label: '広告の品' },
        ],
        customReceiptComments: new Map([
          [
            ReceiptComment.ContactInfo,
            'お問い合わせ先：スギスマホオーダーのお問い合わせフォームよりご連絡ください。',
          ],
        ]),
      })
    }
    case EcSite.SiteType.DELICIA: {
      return createPartnerConfig({
        featureFlags: {
          enableCashOnDeliveryFee: true,
          enableStailerStandardSalesFinalizer: true,
          preferDayBeforePicking: true,
          displayBarcodeInPickList: true,
          productCodeDisplayTypeInPickList: 'scanCode',
          productCodeEmphasizedDigitInPickList: 4,
          enableLinkPointInfo: true,
          useAlternativePicking: true,
          displayLinkToNewProductInOrder: true,
          displayEarnedPointInReceipt: true,
          enableStailerTMS: true,
          enableContants: true,
        },
        uploadImageNameType: UploadImageNameType.productIdAndOrder,
        useYamatoTransportServiceShopIds: [
          '23', // 寿店
          '82', // 篠ノ井店
          '410', // 豊野店
          '411', // 松本広域ネットスーパー広丘店
        ],
        salesBadges: [
          { value: 0, label: 'なし' },
          { value: 1, label: '広告の品' },
        ],
      })
    }
    case EcSite.SiteType.LADYDRUG: {
      return createPartnerConfig({
        featureFlags: {
          enableStailerStandardSalesFinalizer: true,
          preferDayBeforePicking: true,
          shareSameDeliveryTimeForPickup: true,
          displayBarcodeInPickList: true,
          enableLinkPointInfo: true,
          displayLinkToNewProductInOrder: true,
          displayEarnedPointInReceipt: true,
          enableStailerTMS: true,
          enableOutgoingCallHistory: true,
          enableContants: true,
        },
        uploadImageNameType: UploadImageNameType.productIdAndOrder,
        customReceiptComments: new Map([
          [ReceiptComment.Point, '※ ポイント加算はお届け日の数日後です。'],
          [
            ReceiptComment.ContactInfo,
            'お問い合わせ先：レデイのポチッとオーダーアプリのお問い合わせフォームよりご連絡ください。',
          ],
        ]),
      })
    }
    case EcSite.SiteType.OGINO: {
      return createPartnerConfig({
        featureFlags: {
          enableStailerStandardSalesFinalizer: true,
          preferDayBeforePicking: true,
          shareSameDeliveryTimeForPickup: true,
          displayBarcodeInPickList: true,
          isDisplaySplitEarnedPoint: false,
          enableLinkPointInfo: true,
          useAlternativePicking: true,
          displayLinkToNewProductInOrder: true,
          enableStailerTMS: true,
          enableContants: true,
        },
        uploadImageNameType: UploadImageNameType.productIdAndOrder,
      })
    }
    case EcSite.SiteType.TORISEN: {
      return createPartnerConfig({
        featureFlags: {
          enableStailerStandardSalesFinalizer: true,
          displayBarcodeInPickList: true,
          enableLinkPointInfo: true,
          displayLinkToNewProductInOrder: true,
          enableStailerTMS: true,
          enableContants: true,
        },
        uploadImageNameType: UploadImageNameType.productIdAndOrder,
        salesBadges: [
          { value: 0, label: 'なし' },
          { value: 1, label: 'ネット限定' },
          { value: 2, label: '広告の品' },
          { value: 3, label: 'いち推し' },
        ],
      })
    }
    case EcSite.SiteType.SEISENICHIBATOP: {
      return createPartnerConfig({
        featureFlags: {
          enableStailerStandardSalesFinalizer: true,
          preferDayBeforePicking: true,
          shareSameDeliveryTimeForPickup: false,
          displayBarcodeInPickList: true,
          displayLinkToNewProductInOrder: true,
          enableStailerTMS: true,
          enableOutgoingCallHistory: true,
          enableContants: true,
        },
        uploadImageNameType: UploadImageNameType.productIdAndOrder,
        salesBadges: [
          { value: 0, label: 'なし' },
          { value: 1, label: '広告の品' },
          { value: 2, label: '日替わり' },
        ],
      })
    }
    case EcSite.SiteType.ARUK: {
      return createPartnerConfig({
        featureFlags: {
          enableStailerStandardSalesFinalizer: true,
          preferDayBeforePicking: true,
          shareSameDeliveryTimeForPickup: true,
          displayBarcodeInPickList: true,
          displayLinkToNewProductInOrder: true,
          enableStailerTMS: true,
          enableOutgoingCallHistory: true,
          enableContants: true,
        },
        uploadImageNameType: UploadImageNameType.productIdAndOrder,
      })
    }
    case EcSite.SiteType.FUJI: {
      return createPartnerConfig({
        featureFlags: {
          enableCashOnDeliveryFee: true,
          enableStailerStandardSalesFinalizer: true,
          shareSameDeliveryTimeForPickup: true,
          displayBarcodeInPickList: true,
          enableLinkPointInfo: true,
          useAlternativePicking: true,
          displayLinkToNewProductInOrder: true,
          displayEarnedPointInReceipt: true,
          enableMultipleMasterDataUpload: true,
          enableStailerTMS: true,
          enableContants: true,
        },
        uploadImageNameType: UploadImageNameType.productIdAndOrder,
      })
    }
    default: {
      return createPartnerConfig({ featureFlags: {} })
    }
  }
}
